import React, { useState, useEffect } from "react";
import { /*useSelector,*/ useDispatch } from "react-redux";
import MaterialTable from "@material-table/core";
import classes from "./UomType.module.css";
import porDefecto from "./UomTypeUtils";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Dialogo from "../../../components/UI/DialogInfo";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"; //iconos customizados
import {
  restGet,
  restGetColumn,
  restPost,
  restDelete,
  restUpdate,
} from "../../../respository/RESTAPI";
import { useSnackbar } from "notistack";
import { checkNulls, checkLength } from "../../../validations/validateInput";
//import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from "@mui/material/LinearProgress";
import { AxiosResponse } from "axios";
import UomTypeDTO from '../../../dto/uom-type.dto'

//Macros
const REQUIRED_FIELDS = [
  "idOwn",
  "uomName",
  //"handlesDecimal",
  "dateCreated",
  "userCreated",
];
const MAXLENGHT_FIELDS = {
  //if(obj.idOwn){ if (obj.idOwn.length > 20 )
  idOwn: 20,
  uomName: 20,
  handlesDecimal: 20,
  dateCreated: 60,
  userCreated: 20,
  dateModified: 60,
  userModified: 20,
  isVariableWeight: 10,
  overPickingAllowed: 20,
};
const url1 =
  //"https://jsonplaceholder.typicode.com/todos/1"
  "http://localhost:3000/uomtypes";
//"https://api-dev.stglatam.app/ms/v1/maestros/vendors"
//"/ms/v1/maestros/vendors";
//"http://stapi.co/api/v1/rest/Elementos/search";
var url2 = "http://localhost:3000/owners";
/**************/

type Props = {
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

//Funcion principal
const VendorTable = React.memo(function VendorTable(props: Props) {
  //React inits
  
  const [data, setData] = useState<UomTypeDTO[]>();
  //const [dataOwners, setDataOwners] = useState();
  const [dataOwnerLookup, setDataOwnerLookup] = useState<object>();
  const [filtering, setFiltering] = useState(true);
  const [grupingToggle, setGrupingToggle] = useState(false);
  const { enqueueSnackbar /*, closeSnackbar*/ } = useSnackbar();
  const dispatch = useDispatch();
  const activarDialogInfoHandler = (messageParam: string) => {
    dispatch({ type: "activar", message: messageParam });
  };
  const [loadingState, setLoadingState] = useState(false);

  /*
  const desactivarDialogInfoHandler = () => {
    dispatch({ type: "desactivar" });
  };
  */

  //primera carga, simula onmount
  useEffect(() => {
    //placeholder
    setData(porDefecto);
    //REST get vendors
    getRest();
    getRestObj();
  }, []);

  /*****************************/
  /****       CRUD          ****/
  /*****************************/
  //REST calls
  ////GET
  const getRest = async () => {
    setLoadingState(true);
    const x = await restGet(url1, "GET");
    setLoadingState(false);
    setData(x.data);
  };
  //GET Column
  const getRestObj = async () => {
    setLoadingState(true); //elemento grafico de carga
    const x = await restGetColumn(url2, "ownName");
    setLoadingState(false); //elemento grafico de carga
    setDataOwnerLookup(x);
  };
  //CREATE
  const postRest = async (elemento: UomTypeDTO) => {
    console.log("post previa");
    console.log(elemento);
    console.log("post previa");
    elemento.idOwn = +elemento.idOwn;
    elemento.handlesDecimal = Boolean(elemento.handlesDecimal);
    console.log(elemento.isVariableWeight);
    /*
    elemento.handlesDecimal = +elemento.handlesDecimal;
    elemento.idOwn = +elemento.idOwn;
    if (elemento.isVariableWeight === undefined) {
      delete elemento.isVariableWeight;
    } else {
      elemento.isVariableWeight = +elemento.isVariableWeight;
    }
    elemento.overPickingAllowed = +elemento.handlesDecimal;
*/
    setLoadingState(true);
    const x = await restPost(url1, elemento);
    setLoadingState(false);
    if (x !== null && x !== undefined) {
      if (x.status === 200) {
        enqueueSnackbar("Elemento ingresado exitosamente", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error al ingresar elemento", {
          variant: "error",
        });
      }
    }
    getRest();
  };
  //DELETE
  const deleteRest = async (elemento: UomTypeDTO) => {
    console.log("borrando");
    console.log(elemento);
    console.log("borrando");

    const { idUomType } = elemento;
    setLoadingState(true);
    const x = await restDelete(url1, idUomType);
    setLoadingState(false);
 
    if ((x.status = 200)) {
      enqueueSnackbar("Elemento eliminado exitosamente", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Error al eliminado elemento", {
        variant: "error",
      });
    }
    getRest();
  };
  //UPDATE
  const UpdateRest = async (elemento: UomTypeDTO) => {
    //reconvercion explicita ya que lookup x ser input los deja en string
    elemento.idOwn = +elemento.idOwn;
    console.log("update");
    console.log("ele:" + elemento + "url:" + url1 + "id:" + elemento.idUomType);
    console.log(elemento);
    console.log("update");

    elemento.idOwn = +elemento.idOwn;
    elemento.handlesDecimal = Boolean(elemento.handlesDecimal);
    ////elemento.isVariableWeight = Boolean(elemento.isVariableWeight);
    /*
    if (elemento.isVariableWeight === undefined) {
      delete elemento.isVariableWeight;
    } else {
      elemento.isVariableWeight = +elemento.isVariableWeight;
    }
    elemento.overPickingAllowed = +elemento.handlesDecimal;
*/

    setLoadingState(true);
    const x: AxiosResponse<any> = await restUpdate(
      elemento,
      url1,
      elemento.idUomType
    );
    setLoadingState(false);

    if (x !== null && x !== undefined) {
      if (x.status === 204) {
        enqueueSnackbar("Elemento modificado exitosamente", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error al modificar elemento", {
          variant: "error",
        });
      }
    }
    getRest();
  };

  console.log("Render VendorTable");
  console.log(data);
  const columns = [
    {
      title: "ID",
      field: "idUomType",
      defaultSort: "asc",
      /*sorting: false*/ editable: "never",
      cellStyle: { color: "blue" },
      //headerStyle: {color: "purple"}
    }, //orden por defecto o desabilitar orden y edicion
    {
      title: "Dueño",
      field: "idOwn",
      type: "numeric",
      //validate: (rowData) => (rowData.idOwn == null ? "*Requerido" : ""),
      //render: (rowData) => getOwnerName(rowData.idOwn), ///*** fucnional pero no requerido xk lo hace lookup */
      lookup: dataOwnerLookup,
      //defaultGroupOrder:0 //agrupa este campo por defecto, con valor 1 se desabilita el agrupamiento
      //hiddenByColumnsButton: true,
      validate: (rowData: UomTypeDTO) => {
        if (rowData.idOwn === undefined /*|| rowData.idOwn === ""*/) {
          return "*Requerido";
        }
      },

      //validate: (rowData) => (rowData.idOwn == null ? "Requerido" : ""),
    },
    {
      title: "Unidad",
      field: "uomName",
      validate: (rowData: UomTypeDTO) => {
        if (rowData.uomName === undefined || rowData.uomName === "") {
          return "*Requerido";
        } else {
          if (rowData.uomName.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.uomName.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Decimales",
      field: "handlesDecimal",
      /*type: "numeric",*/ type: "boolean",
      //lookup: { true: "Si", false: "No" },
      //render: (rowData) => <div style={{background: rowData.hasInspection != 0 ? "white" : "#faa"}}>{rowData.hasInspection !== "null" ? rowData.hasInspection : "-"}</div>,
      /*
      validate: (rowData: UomTypeDTO) => {
        if (
          rowData.handlesDecimal === undefined ||
          rowData.handlesDecimal === null
        ) {
          //console.log(typeof rowData.handlesDecimal)
          return "*Requerido";
        }
      },*/
      validate: (rowData: UomTypeDTO) => {
        if (rowData.handlesDecimal === undefined || rowData.handlesDecimal === null) {
          return "*Requerido";
        }
      },
      hidden: false,
    }, //se puede condicionar el valor para cambiar valor k se muestra

    {
      title: "Creación",
      field: "dateCreated",
      type: "datetime",
      validate: (rowData: UomTypeDTO) => {
        if (rowData.dateCreated === undefined || rowData.dateCreated === null) {
          //console.log(typeof rowData.handlesDecimal)
          return "*Requerido";
        }
      },
    },
    {
      title: "Fecha Mod.",
      field: "dateModified",
      type: "datetime",
      hidden: true,
    },

    {
      title: "Usuario Crea.",
      field: "userCreated",
      hidden: false,
      validate: (rowData: UomTypeDTO) => {
        if (rowData.userCreated === undefined /*|| rowData.idOwn === ""*/) {
          return "*Requerido";
        }
      },
    },
    { title: "Usuario Mod.", field: "userModified", hidden: true },
    {
      title: "Peso variable",
      field: "isVariableWeight",
      /*type: "numeric",*/ type: "boolean",
      //lookup: { true: "Si", false: "No" },
      //render: (rowData) => <div style={{background: rowData.hasInspection != 0 ? "white" : "#faa"}}>{rowData.hasInspection !== "null" ? rowData.hasInspection : "-"}</div>,
      hidden: false,
    },
    {
      title: "Sobre-picking",
      field: "overPickingAllowed",
      /*type: "numeric",*/ type: "boolean",
      //lookup: { 0: "Si", 1: "No" },
      //render: (rowData) => <div style={{background: rowData.hasInspection != 0 ? "white" : "#faa"}}>{rowData.hasInspection !== "null" ? rowData.hasInspection : "-"}</div>,

      hidden: false,
    },
  ];

  return (
    <div className="App">
      <Dialogo />
      <div className={classes.Titulo}></div>
      <button onClick={() => setFiltering(!filtering)}>
        <span>Filtro Busqueda</span>
      </button>
      <button onClick={() => setGrupingToggle(!grupingToggle)}>
        <span>Filtro Agrupar</span>
      </button>
      {loadingState && <LinearProgress />}
      <MaterialTable
        title="Unidades de medida"
        data={data}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsPerPage: "N°",
            labelRowsSelect: "Filas",
          },
          toolbar: {
            nRowsSelected: "{0} Filas Seleccionadas",
            //exportName: "Exportar CSV",
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar",
            addRemoveColumns: "Agrega o quita columnas",
          },
          header: {
            actions: "Acciones",
          },
          body: {
            addTooltip: "Agregar",
            emptyDataSourceMessage: "No hay datos",
            filterRow: {
              filterTooltip: "Filtro",
            },
            editRow: {
              deleteText: "¿Esta seguro que quiere borrar esta fila?",
              saveTooltip: "Guardar",
              cancelTooltip: "Cancelar",
            },
          },
          grouping: {
            placeholder: "Arrastra las etiquetas de las columnas aqui",
          },
        }}
        columns={columns as any}
        editable={{
          //create
          onRowAdd: (newRow) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (!checkNulls(newRow, REQUIRED_FIELDS)) {
                activarDialogInfoHandler(
                  "No pueden haber campos requeridos vacios"
                );
                console.log("tiene nulos");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else if (!checkLength(newRow, MAXLENGHT_FIELDS)) {
                activarDialogInfoHandler("Infringe maximo caracteres");
                console.log("Infringe maximo caracteres");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else {
                console.log("tabledata");
                console.log(newRow);
                delete newRow["tableData"];
                console.log(newRow);
                /*
          newRow.map(r => {
            console.log(r)
            const {tableData, ...record} = r;
            return record;
          });
           
          //const {tableData, ...record} = newRow;
          const{id, idUomType} = newRow;
          const record =  {id, idUomType}
*/
                console.log("Elemento agregado");
                //const record ={ "vendorName": newRow.vendorName, "idUomType": newRow.idUomType}
                console.log(newRow);
                console.log("Elemento agregado end");
                //setData([...data, record])
                postRest(newRow);
              }
              setTimeout(() => resolve(), 100); //cierra la promesa
            }),
          //delete
          onRowDelete: (selectedRow: UomTypeDTO) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (props.setMessage) {
                props.setMessage("Invalid");
                console.log("prop no validado");
                reject();
              }
              const updatedData = [...data];
              console.log(selectedRow.tableData.id);
              updatedData.splice(selectedRow.tableData.id, 1);
              console.log(selectedRow);
              deleteRest(selectedRow);
              setData(updatedData);
              setTimeout(() => {
                props.setMessage("Successfully updated!");
                resolve();
              }, 100);
            }),
          //update
          onRowUpdate: (newRow, oldRow) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (!checkNulls(newRow, REQUIRED_FIELDS)) {
                activarDialogInfoHandler(
                  "No pueden haber campos requeridos vacios"
                );
                console.log("tiene nulos");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else if (!checkLength(newRow, MAXLENGHT_FIELDS)) {
                activarDialogInfoHandler("Infringe maximo caracteres");
                console.log("Infringe maximo caracteres");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else {
                console.log(oldRow, newRow);

                console.log("Updating");
                console.log(oldRow.tableData);
                console.log("Updating");

                const updatedData = [...data];
                updatedData[oldRow.tableData.id] = newRow;
                console.log("PostUpdating");
                console.log(newRow);
                //console.log(updatedData);
                console.log("PostUpdating");

                UpdateRest(newRow);
                //update(updatedData);
                props.setMessage("Successfully updated!");
              }
              setTimeout(() => resolve(), 100); //cierra la promesa
            }),
        }}
        actions={[
          {
            icon: () => <InsertDriveFileIcon />,
            tooltip: "Detalle",
            onClick: (e, data) => {
              alert("Descargar detalle");
              console.log(data);
            },
            //isFreeAction: true //toggle para que el boton este en la columna o generalizado
          },
        ]}
        onSelectionChange={(selectedRow) => console.log(selectedRow)}
        options={{
          sorting: true,
          //searchText:"GinoMP", //busqueda x defecto
          paging: true,
          pageSize: 20, // cantidad registros inicial
          emptyRowsWhenPaging: false, //borra lineas vacias
          pageSizeOptions: [6, 12, 20, 50, 100], // opcion dropdown cantidad elementos
          filtering: filtering, //habilita busquedas por columna
          //toolbar: true,
          //exportButton: true, //habilita csv pdf

          ////exportFileName: "Maestro_Proveedores", //nombre archivo
          addRowPosition: "first",
          actionsColumnIndex: -1,
          columnsButton: true,
          grouping: grupingToggle,
          rowStyle: (data, index) =>
            index % 2 === 0 ? { background: "#eef" } : null,
          headerStyle: {
            background: "#def",
            fontStyle: "Arial Black",
            fontSize: "1.1em",
          },

          selectionProps: (rowData: UomTypeDTO) => ({
            //disable: rowData.name!=="",  //desabilitar checkbox si campo es vacio
            color: "primary",
          }),
          exportMenu: [
            {
              label: "Exportar PDF",
              exportFunc: (cols, datas) => ExportPdf(cols, datas, "vendorPDF"),
            },
            {
              label: "Exportar CSV",
              exportFunc: (cols, datas) => ExportCsv(cols, datas, "vendorCSV"),
            },
          ],
          selection: true,
          //showSelectAllCheckbox: false,
          //showTextRowsSelected: false,
        }}
      />
    </div>
  );
});

export default VendorTable;
