/*
import React from 'react'
import { buttonStyles } from './Button.styles'

const Button = ({ onClick, text }) => (
  <button onClick={onClick} className={buttonStyles}>
    {text}
  </button>
)

export default Button
*/ 

import React, { MouseEventHandler } from 'react'
//import { buttonStyles } from './Button.styles'

type Props = {
  onClick: MouseEventHandler,
  text: string,
}

const BtnTypescript = ({ onClick, text }: Props) => (
  <button onClick={onClick} /*className={buttonStyles}*/>
    {text}
  </button>
)

export default BtnTypescript