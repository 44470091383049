import React, { useState, useEffect } from "react";
import { /*useSelector,*/ useDispatch } from "react-redux";
import MaterialTable from "@material-table/core";
import classes from "./Item.module.css";
import porDefecto from "./ItemUtils";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Dialogo from "../../../components/UI/DialogInfo";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"; //iconos customizados
import {
  restGet,
  restGetColumn,
  restPost,
  restDelete,
  restUpdate,
} from "../../../respository/RESTAPI";
import { useSnackbar } from "notistack";
import { checkNulls, checkLength } from "../../../validations/validateInput";
//import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from "@mui/material/LinearProgress";
import { AxiosResponse } from "axios";
import ItemDTO from '../../../dto/item.dto'

//Macros
const REQUIRED_FIELDS = [
  "code",
  "description",
  "longName",
  "shortName",
  "status"
];
const MAXLENGHT_FIELDS = {
  //if(obj.idOwn){ if (obj.idOwn.length > 20 )

     code :  30 ,
     altItemCode :  30 ,
     description :  255 ,
     idGrpItem1 : 40,
     idGrpItem2 : 40,
     idGrpItem3 : 4,
     idGrpItem4 : 40,
     longName :  60,
     shortName : 20 ,
     status : 20,//boolean
     comment : 100,
     shelfLife : 40,
     expirationDays : 40,
     ctrlSerialInbound : 20, //boolean
     ctrlSerialInternal : 20, //boolean
     ctrlSerialOutbound : 20, //boolean
     lotControlInbound : 20, //boolean
     lotControlInternal : 20, //boolean
     lotControlOutbound : 20, //boolean
     weight : 40,
     volume : 40,
     length : 40,
     width : 40,
     height : 40,
     nestedVolume : 40,
     inspectionRequerid : 20, //boolean
     inspectionCode : 5,
     idPutawayZone : 40,
     controlExpDate : 20, //boolean
     controlFabDate : 20,  //boolean
     acumulable : 20, //boolean
     reOrderPoint : 40,
     reOrderQty : 40,
     palletQty : 40,
     cutMinimum : 40,
     originator : 30,
     vasProfile : 10,
     hazard : 40,
     price : 40,
     inventoryType : 5,
     stackingSequence : 40,
     commentControl : 20, //boolean
     compatibilityCode : 5,
     MSDSUrl : 200,
     pictureUrl : 200,
     grpClass1 : 20,
     grpClass2 : 20,
     grpClass3 : 20,
     grpClass4 : 20,
     grpClass5 : 20,
     grpClass6 : 20,
     grpClass7 : 20,
     grpClass8 : 20,
     specialField1 : 20,
     specialField2 : 20,
     specialField3 : 20,
     specialField4 : 20,
     dateCreated : 60,
     userCreated : 20,
     dateModified : 60,
     userModified : 20,
     idOwn : 20
};
const url1 =
  //"https://jsonplaceholder.typicode.com/todos/1"
  //"http://localhost:3000/item-uoms";
  "http://localhost:3000/items";
  //"http://localhost:3000/item-uoms?filter[include][]=item&filter[include][][relation]=uomtype";
//"https://api-dev.stglatam.app/ms/v1/maestros/vendors"
//"/ms/v1/maestros/vendors";
//"http://stapi.co/api/v1/rest/Elementos/search";
var url2 = "http://localhost:3000/items";
/**************/

type Props = {
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

//Funcion principal
const VendorTable = React.memo(function VendorTable(props: Props) {
  //React inits
  
  const [data, setData] = useState<ItemDTO[]>();
  //const [dataOwners, setDataOwners] = useState();
  const [dataItemLookup, setdataItemLookup] = useState<object>();
  const [filtering, setFiltering] = useState(false);
  const [grupingToggle, setGrupingToggle] = useState(false);
  const { enqueueSnackbar /*, closeSnackbar*/ } = useSnackbar();
  const dispatch = useDispatch();
  const activarDialogInfoHandler = (messageParam: string) => {
    dispatch({ type: "activar", message: messageParam });
  };
  const [loadingState, setLoadingState] = useState(false);

  /*
  const desactivarDialogInfoHandler = () => {
    dispatch({ type: "desactivar" });
  };
  */

  //primera carga, simula onmount
  useEffect(() => {
    //placeholder
    setData(porDefecto);
    //REST get vendors
    getRest();
    getRestObj();
  }, []);

  /*****************************/
  /****       CRUD          ****/
  /*****************************/
  //REST calls
  ////GET
  const getRest = async () => {
    setLoadingState(true);
    const x = await restGet(url1, "GET");
    setLoadingState(false);
    setData(x.data);
  };
  //GET Column
  const getRestObj = async () => {
    setLoadingState(true); //elemento grafico de carga
    const x = await restGetColumn(url2, "shortName");
    setLoadingState(false); //elemento grafico de carga
    setdataItemLookup(x);
  };
  //CREATE
  const postRest = async (elemento: ItemDTO) => {
    console.log("post previa");
    console.log(elemento);
    


    //elemento.idOwn = +elemento.idOwn;

    
    console.log("post previa");
    console.log(elemento);
    setLoadingState(true);
    const x = await restPost(url1, elemento);
    setLoadingState(false);
    if (x !== null && x !== undefined) {
      if (x.status === 200) {
        enqueueSnackbar("Elemento ingresado exitosamente", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error al ingresar elemento", {
          variant: "error",
        });
      }
    }
    getRest();
  };
  //DELETE
  const deleteRest = async (elemento: ItemDTO) => {
    console.log("borrando");
    console.log(elemento);
    console.log("borrando");

    const { id } = elemento;
    setLoadingState(true);
    const x = await restDelete(url1, id);
    setLoadingState(false);
    console.log("estadoRest:" + x.status);
    if ((x.status = 200)) {
      enqueueSnackbar("Elemento eliminado exitosamente", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Error al eliminado elemento", {
        variant: "error",
      });
    }
    getRest();
  };
  //UPDATE
  const UpdateRest = async (elemento: ItemDTO) => {

    //reconvercion explicita ya que lookup x ser input los deja en string
    //elemento.idOwn = +elemento.idOwn;
    console.log("update");
    console.log("ele:" + elemento + "url:" + url1 + "id:" + elemento.id);
    console.log("update");
    setLoadingState(true);
    const x: AxiosResponse<any> = await restUpdate(elemento, url1, elemento.id);
    setLoadingState(false);
    console.log("estadoRest:" + x.status);
    if (x !== null && x !== undefined) {
      if (x.status === 204) {
        enqueueSnackbar("Elemento modificado exitosamente", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error al modificar elemento", {
          variant: "error",
        });
      }
    }
    getRest();
  };

  console.log("Render VendorTable");

  console.log("para lookup")

  console.log("para lookup")
  console.log(data);
  const columns = [
/*     {
      title: "ID",
      field: "id",
      defaultSort: "asc",
      //sorting: false,
      editable: "never",
      cellStyle: { color: "blue" },
      //headerStyle: {color: "purple"}
    }, //orden por defecto o desabilitar orden y edicion */
   
    {
      title: "Dueño",
      field: "idOwn",
      required: true,
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (
          rowData.idOwn === undefined ||
          isNaN(rowData.idOwn)
        ) {
          return "*Requerido"
        }
      },
    },
    

    {
      title: "Sector",
      field: "idGrpItem1",
      export: false,
      hidden: false,
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (
          rowData.idGrpItem1 === undefined ||
          isNaN(rowData.idGrpItem1)
        ) {
          
        }
      },
    },
    {
      title: "Rubro",
      field: "idGrpItem2",
      type: "numeric",
      export: false,
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.idGrpItem2 === undefined ||
          isNaN(rowData.idGrpItem2)
        ) {
          
        }
      },
    },
    {
      title: "Familia",
      field: "idGrpItem3",
      export: false,
      hidden: false,
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (
          rowData.idGrpItem3 === undefined ||
          isNaN(rowData.idGrpItem3)
        ) {
        return "*Requerido";
        }
      },
    },
    {
      title: "SubFamilia",
      field: "idGrpItem4",
      export: false,
      hidden: false,
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (
          rowData.idGrpItem4 === undefined ||
          isNaN(rowData.idGrpItem4)
        ) {
          
        }
      },
    },
   
    {
      title: "Cód. Item",
      field: "code",

      //validate: (rowData) => (rowData.idOwn == null ? "*Requerido" : ""),
      //render: (rowData) => getOwnerName(rowData.idOwn), ///*** fucnional pero no requerido xk lo hace lookup */
      //lookup: dataItemLookup,
      //defaultGroupOrder:0 //agrupa este campo por defecto, con valor 1 se desabilita el agrupamiento
      //hiddenByColumnsButton: true,
      validate: (rowData: ItemDTO) => {
        if (rowData.code === undefined || rowData.code === null /*|| rowData.idOwn === ""*/) {
          return "*Requerido";
        }
      },

      //validate: (rowData) => (rowData.idOwn == null ? "Requerido" : ""),
    },
    {
      title: "Descripción",
      field: "description",
      validate: (rowData: ItemDTO) => {
        if (rowData.description === undefined || rowData.description === ""|| rowData.description === null) {
          return "*Requerido";
        } else {
          if (rowData.description.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.description.length > 255) {
            return "Debe conterner menos de 30 caracteres";
          }
        }
      },
    },
    {
      title: "Nombre Largo",
      required: true,
      field: "longName",
      validate: (rowData: ItemDTO) => {
        if (rowData.longName === undefined || rowData.longName === ""|| rowData.longName === null) {
          return "*Requerido"
        } else {
          if (rowData.longName.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.longName.length > 60) {
            return "Debe conterner menos de 60 caracteres";
          }
        }
      },
    },
    {
      title: "Nombre Corto",
      required: true,
      field: "shortName",
      
      validate: (rowData: ItemDTO) => {
        if (rowData.shortName === undefined || rowData.shortName === ""|| rowData.shortName === null) {
          return "*Requerido"
        } else {
          if (rowData.shortName.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.shortName.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },

    {
      title: "Activo",
      field: "status",
      required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.status === undefined || rowData.status === null) {
          return "*Requerido";
        }
      },
    },
    
    {
      title: "Cód. Alter",
      field: "altItemCode",
      hidden: true, 
      export: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.altItemCode === undefined || rowData.altItemCode === ""|| rowData.altItemCode === null) {
         
        } else {
          if (rowData.altItemCode.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.altItemCode.length > 30) {
            return "Debe conterner menos de 30 caracteres";
          }
        }
      },
    },
    {
      title: "Comentario",
      field: "comment",
      validate: (rowData: ItemDTO) => {
        if (rowData.comment === undefined || rowData.comment === ""|| rowData.comment === null) {
        ;
        } else {
          if (rowData.comment.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.comment.length > 100) {
            return "Debe conterner menos de 100 caracteres";
          }
        }
      },
    },
    {
      title: "Vida Útil",
      field: "shelfLife",
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (
          rowData.shelfLife === undefined ||
          isNaN(rowData.shelfLife)
        ) {
          
        }
      },
    },
    {
      title: "Tiempo Exp.",
      field: "expirationDays",
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (
          rowData.expirationDays === undefined ||
          isNaN(rowData.expirationDays)
        ) {
          
        }
      },
    },
    {
      title: "Serial Inbound",
      field: "ctrlSerialInbound",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.ctrlSerialInbound === undefined || rowData.ctrlSerialInbound === null) {
          //return "*Requerido";
        }
      },
    },
    {
      title: "Serial Internal",
      field: "ctrlSerialInternal",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.ctrlSerialInternal === undefined || rowData.ctrlSerialInternal === null) {
          //return "*Requerido";
        }
      },
    },
    {
      title: "Serial Outbound",
      field: "ctrlSerialOutbound",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.ctrlSerialOutbound === undefined || rowData.ctrlSerialOutbound === null) {
          //return "*Requerido";
        }
      },
    },
    {
      title: "Lot Ctrl Inbound",
      field: "lotControlInbound",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.lotControlInbound === undefined || rowData.lotControlInbound === null) {
          //return "*Requerido";
        }
      },
    },
    {
      title: "Ctrl Internal",
      field: "lotControlInternal",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.lotControlInternal === undefined || rowData.lotControlInternal === null) {
          //return "*Requerido";
        }
      },
    },
    {
      title: "Ctrl. Outbound",
      field: "lotControlOutbound",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.lotControlOutbound === undefined || rowData.lotControlOutbound === null) {
          //return "*Requerido";
        }
      },
    },

    {
      title: "Largo",
      field: "length",
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (rowData.length === undefined || rowData.length === null/*|| rowData.length === "" */) {
        } else {
          if (rowData.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.length > 999999999999) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Ancho",
      field: "width",
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (rowData.width === undefined || rowData.width === null) {
        } else {
          if (rowData.width < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.width.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Alto",
      field: "height",
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (rowData.height === undefined || rowData.height === null) {
        } else {
          if (rowData.height < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.height.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Volumen",
      field: "volume",
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (rowData.volume === undefined || rowData.volume === null) {
        } else {
          if (rowData.volume < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.volume.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Peso",
      field: "weight",
      type: "numeric",
      validate: (rowData: ItemDTO) => {
        if (rowData.weight === undefined || rowData.weight === null) {
        } else {
          if (rowData.weight < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.weight.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },

    {
      title: "Volumen Anidado",
      field: "nestedVolume",
      type: "numeric",
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.nestedVolume === undefined ||
          rowData.nestedVolume === null
        ) {
        } else {
          if (rowData.nestedVolume < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.nestedVolume.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },

    {
      title: "Inspección Requerida",
      field: "inspectionRequerid",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.inspectionRequerid === undefined || rowData.inspectionRequerid === null) {
          //return "*Requerido";
        }
      },
    },

    {
      title: "Cód Inspección",
      field: "inspectionCode",
      validate: (rowData: ItemDTO) => {
        if (rowData.inspectionCode === undefined || rowData.inspectionCode === "" || rowData.inspectionCode === null) {
        ;
        } else {
          if (rowData.inspectionCode.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.inspectionCode.length > 5) {
            return "Debe conterner menos de 5 caracteres";
          }
        }
      },
    },
    {
      title: "ID Zona PutAway",
      field: "idPutawayZone",
      type: "numeric",
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.idPutawayZone === undefined ||
          rowData.idPutawayZone === null
        ) {
        } else {
          if (rowData.idPutawayZone < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.idPutawayZone.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },



    {
      title: "Ctrl. Fecha Exp.",
      field: "controlExpDate",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.controlExpDate === undefined || rowData.controlExpDate === null) {
          //return "*Requerido";
        }
      },
    },
    {
      title: "Ctrl. Fecha Fab.",
      field: "controlFabDate",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.controlFabDate === undefined || rowData.controlFabDate === null) {
          //return "*Requerido";
        }
      },
    },
    {
      title: "Acumulable",
      field: "acumulable",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.acumulable === undefined || rowData.acumulable === null) {
          //return "*Requerido";
        }
      },
    },

    {
      title: "Punto de Reorden",
      field: "reOrderPoint",
      type: "numeric",
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.reOrderPoint === undefined ||
          rowData.reOrderPoint === null
        ) {
        } else {
          if (rowData.reOrderPoint < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.reOrderPoint.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Cantidad Reorden",
      field: "reOrderQty",
      type: "numeric",
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.reOrderQty === undefined ||
          rowData.reOrderQty === null
        ) {
        } else {
          if (rowData.reOrderQty < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.reOrderQty.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },

    {
      title: "Cantidad Pallet",
      field: "palletQty",
      type: "numeric",
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.palletQty === undefined ||
          rowData.palletQty === null
        ) {
        } else {
          if (rowData.palletQty < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.palletQty.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },

    {
      title: "Cortar Minimos",
      field: "cutMinimum",
      type: "numeric",
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.cutMinimum === undefined ||
          rowData.cutMinimum === null
        ) {
        } else {
          if (rowData.cutMinimum < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.cutMinimum.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },

    {
      title: "Originador",
      field: "originator",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.originator === undefined ||
          rowData.originator === "" ||
          rowData.originator === null
        ) {
          return;
        } else {
          if (rowData.originator.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.originator.length > 30) {
            return "Debe conterner menos de 30 caracteres";
          }
        }
      },
    },

    {
      title: "Perfil VAS",
      field: "vasProfile",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.vasProfile === undefined ||
          rowData.vasProfile === "" ||
          rowData.vasProfile === null
        ) {
          return;
        } else {
          if (rowData.vasProfile.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.vasProfile.length > 10) {
            return "Debe conterner menos de 10 caracteres";
          }
        }
      },
    },

    {
      title: "Precaución",
      field: "hazard",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.hazard === undefined || rowData.hazard === null) {
          //return "*Requerido";
        }
      },
    },

    {
      title: "Precio",
      field: "price",
      type: "numeric",
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.price === undefined ||
          rowData.price === null
        ) {
        } else {
          if (rowData.price < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.price.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },

    {
      title: "Tipo Inventario",
      field: "inventoryType",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.inventoryType === undefined ||
          rowData.inventoryType === "" ||
          rowData.inventoryType === null
        ) {
          return;
        } else {
          if (rowData.inventoryType.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.inventoryType.length > 5) {
            return "Debe conterner menos de 5 caracteres";
          }
        }
      },
    },

    {
      title: "Sec. Apilado",
      field: "stackingSequence",
      type: "numeric",
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.stackingSequence === undefined ||
          rowData.stackingSequence === null
        ) {
        } else {
          if (rowData.stackingSequence < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.stackingSequence.toString().length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },

    {
      title: "Precaución",
      field: "commentControl",
      //required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemDTO) => {
        if (rowData.commentControl === undefined || rowData.commentControl === null) {
          //return "*Requerido";
        }
      },
    },


    {
      title: "Cód. Compatibilidad",
      field: "compatibilityCode",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.compatibilityCode === undefined ||
          rowData.compatibilityCode === "" ||
          rowData.compatibilityCode === null
        ) {
          return;
        } else {
          if (rowData.compatibilityCode.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.compatibilityCode.length > 5) {
            return "Debe conterner menos de 5 caracteres";
          }
        }
      },
    },

    {
      title: "MSDSUrl",
      field: "MSDSUrl",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.MSDSUrl === undefined ||
          rowData.MSDSUrl === "" ||
          rowData.MSDSUrl === null
        ) {
          return;
        } else {
          if (rowData.compatibilityCode.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.compatibilityCode.length > 200) {
            return "Debe conterner menos de 200 caracteres";
          }
        }
      },
    },

    {
      title: "Foto URL",
      field: "pictureUrl",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.pictureUrl === undefined ||
          rowData.pictureUrl === "" ||
          rowData.pictureUrl === null
        ) {
          return;
        } else {
          if (rowData.pictureUrl.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.pictureUrl.length > 200) {
            return "Debe conterner menos de 200 caracteres";
          }
        }
      },
    },

    {
      title: "Grupo 1",
      field: "grpClass1",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.grpClass1 === undefined ||
          rowData.grpClass1 === "" ||
          rowData.grpClass1 === null
        ) {
          return;
        } else {
          if (rowData.grpClass1.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.grpClass1.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Grupo 2",
      field: "grpClass2",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.grpClass2 === undefined ||
          rowData.grpClass2 === "" ||
          rowData.grpClass2 === null
        ) {
          return;
        } else {
          if (rowData.grpClass2.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.grpClass2.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Grupo 3",
      field: "grpClass3",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.grpClass3 === undefined ||
          rowData.grpClass3 === "" ||
          rowData.grpClass3 === null
        ) {
          return;
        } else {
          if (rowData.grpClass3.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.grpClass3.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Grupo 4",
      field: "grpClass4",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.grpClass4 === undefined ||
          rowData.grpClass4 === "" ||
          rowData.grpClass4 === null
        ) {
          return;
        } else {
          if (rowData.grpClass4.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.grpClass4.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Grupo 5",
      field: "grpClass5",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.grpClass5 === undefined ||
          rowData.grpClass5 === "" ||
          rowData.grpClass5 === null
        ) {
          return;
        } else {
          if (rowData.grpClass5.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.grpClass5.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Grupo 6",
      field: "grpClass6",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.grpClass6 === undefined ||
          rowData.grpClass6 === "" ||
          rowData.grpClass6 === null
        ) {
          return;
        } else {
          if (rowData.grpClass6.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.grpClass6.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Grupo 7",
      field: "grpClass7",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.grpClass7 === undefined ||
          rowData.grpClass7 === "" ||
          rowData.grpClass7 === null
        ) {
          return;
        } else {
          if (rowData.grpClass7.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.grpClass7.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Grupo 8",
      field: "grpClass8",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.grpClass8 === undefined ||
          rowData.grpClass8 === "" ||
          rowData.grpClass8 === null
        ) {
          return;
        } else {
          if (rowData.grpClass8.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.grpClass8.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
   
    {
      title: "specialField1",
      field: "specialField1",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.specialField1 === undefined ||
          rowData.specialField1 === "" ||
          rowData.specialField1 === null
        ) {
          return;
        } else {
          if (rowData.specialField1.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.specialField1.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "specialField2",
      field: "specialField2",
      emptyValue: () => <em>No</em>,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.specialField2 === undefined ||
          rowData.specialField2 === "" ||
          rowData.specialField2 === null
        ) {
          return;
        } else {
          if (rowData.specialField2.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.specialField2.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "specialField3",
      field: "specialField3",
      emptyValue: () => <em>No</em>,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.specialField3 === undefined ||
          rowData.specialField3 === "" ||
          rowData.specialField3 === null
        ) {
          return;
        } else {
          if (rowData.specialField3.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.specialField3.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "specialField4",
      field: "specialField4",
      emptyValue: () => <em>No</em>,
      export: false,
      hidden: true,
      validate: (rowData: ItemDTO) => {
        if (
          rowData.specialField4 === undefined ||
          rowData.specialField4 === "" ||
          rowData.specialField4 === null
        ) {
          return;
        } else {
          if (rowData.specialField4.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.specialField4.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Creación",
      field: "dateCreated",
      type: "datetime",
      hidden: true,
    },
    {
      title: "Fecha Mod.",
      field: "dateModified",
      type: "datetime",
      hidden: true,
    },
    { title: "Usuario Crea.", field: "userCreated", hidden: true, export: false },
    { title: "Usuario Mod.", field: "userModified", hidden: true, export: false },

  ];

  return (
    <div className="App">
      <Dialogo />
      <div className={classes.Titulo}></div>
      <button onClick={() => setFiltering(!filtering)}>
        <span>Filtro Busqueda</span>
      </button>
      <button onClick={() => setGrupingToggle(!grupingToggle)}>
        <span>Filtro Agrupar</span>
      </button>
      {loadingState && <LinearProgress />}
      <MaterialTable
        title="Ítems"
        data={data}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsPerPage: "N°",
            labelRowsSelect: "Filas",
          },
          toolbar: {
            nRowsSelected: "{0} Filas Seleccionadas",
            //exportName: "Exportar CSV",
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar",
            addRemoveColumns: "Agrega o quita columnas",
          },
          header: {
            actions: "Acciones",
          },
          body: {
            addTooltip: "Agregar",
            emptyDataSourceMessage: "No hay datos",
            filterRow: {
              filterTooltip: "Filtro",
            },
            editRow: {
              deleteText: "¿Esta seguro que quiere borrar esta fila?",
              saveTooltip: "Guardar",
              cancelTooltip: "Cancelar",
            },
          },
          grouping: {
            placeholder: "Arrastra las etiquetas de las columnas aqui",
          },
        }}
        columns={columns as any}
        editable={{
          //create
          onRowAdd: (newRow) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (!checkNulls(newRow, REQUIRED_FIELDS)) {
                activarDialogInfoHandler(
                  "No pueden haber campos requeridos vacios"
                );
                console.log("tiene nulos");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else if (!checkLength(newRow, MAXLENGHT_FIELDS)) {
                activarDialogInfoHandler("Infringe maximo caracteres");
                console.log("Infringe maximo caracteres");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else {
                console.log("tabledata");
                console.log(newRow);
                delete newRow["tableData"];
                console.log(newRow);
                /*
          newRow.map(r => {
            console.log(r)
            const {tableData, ...record} = r;
            return record;
          });
           
          //const {tableData, ...record} = newRow;
          const{id, idVendor} = newRow;
          const record =  {id, idVendor}
*/
                console.log("Elemento agregado");
                //const record ={ "vendorName": newRow.vendorName, "idVendor": newRow.idVendor}
                console.log(newRow);
                console.log("Elemento agregado end");
                //setData([...data, record])
                postRest(newRow);
              }
              setTimeout(() => resolve(), 100); //cierra la promesa
            }),
          //delete
          onRowDelete: (selectedRow: ItemDTO) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (props.setMessage) {
                props.setMessage("Invalid");
                console.log("prop no validado");
                reject();
              }
              const updatedData = [...data];
              console.log(selectedRow.tableData.id);
              updatedData.splice(selectedRow.tableData.id, 1);
              console.log(selectedRow);
              deleteRest(selectedRow);
              setData(updatedData);
              setTimeout(() => {
                props.setMessage("Successfully updated!");
                resolve();
              }, 100);
            }),
          //update
          onRowUpdate: (newRow, oldRow) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (!checkNulls(newRow, REQUIRED_FIELDS)) {
                activarDialogInfoHandler(
                  "No pueden haber campos requeridos vacios"
                );
                console.log("tiene nulos");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else if (!checkLength(newRow, MAXLENGHT_FIELDS)) {
                activarDialogInfoHandler("Infringe maximo caracteres");
                console.log("Infringe maximo caracteres");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else {
                console.log(oldRow, newRow);

                console.log("Updating");
                console.log(oldRow.tableData);
                console.log("Updating");

                const updatedData = [...data];
                updatedData[oldRow.tableData.id] = newRow;
                console.log("PostUpdating");
                console.log(newRow);
                //console.log(updatedData);
                console.log("PostUpdating");

                UpdateRest(newRow);
                //update(updatedData);
                props.setMessage("Successfully updated!");
              }
              setTimeout(() => resolve(), 100); //cierra la promesa
            }),
        }}
        actions={[
          {
            icon: () => <InsertDriveFileIcon />,
            tooltip: "Detalle",
            onClick: (e, data) => {
              alert("Descargar detalle");
              console.log(data);
            },
            //isFreeAction: true //toggle para que el boton este en la columna o generalizado
          },
        ]}
        onSelectionChange={(selectedRow) => console.log(selectedRow)}
        options={{
          sorting: true,
          //searchText:"GinoMP", //busqueda x defecto
          paging: true,
          pageSize: 20, // cantidad registros inicial
          emptyRowsWhenPaging: false, //borra lineas vacias
          pageSizeOptions: [6, 12, 20, 50, 100], // opcion dropdown cantidad elementos
          filtering: filtering, //habilita busquedas por columna
          //toolbar: true,
          //exportButton: true, //habilita csv pdf

          ////exportFileName: "Maestro_Proveedores", //nombre archivo
          addRowPosition: "first",
          actionsColumnIndex: -1,
          columnsButton: true,
          grouping: grupingToggle,
          rowStyle: (data, index) =>
            index % 2 === 0 ? { background: "#eef" } : null,
          headerStyle: {
            background: "#def",
            fontStyle: "Arial Black",
            fontSize: "1em",
          },

          selectionProps: (rowData: ItemDTO) => ({
            //disable: rowData.name!=="",  //desabilitar checkbox si campo es vacio
            color: "primary",
          }),
          exportMenu: [
            {
              label: "Exportar PDF",
              exportFunc: (cols, datas) => ExportPdf(cols, datas, "vendorPDF"),
            },
            {
              label: "Exportar CSV",
              exportFunc: (cols, datas) => ExportCsv(cols, datas, "vendorCSV"),
            },
          ],
          selection: true,
          //showSelectAllCheckbox: false,
          //showTextRowsSelected: false,
        }}
      />
    </div>
  );
});

export default VendorTable;