

export function checkNulls(obj, REQUIRED_FIELDS) {
    /*
  for (var key in obj) {
      if (obj[key] !== null && obj[key] != "")
          return false;
  }
  */

    for (var i of REQUIRED_FIELDS) {
      console.log("vali");
      console.log(i);
      console.log(obj[i]);
      //console.log(obj[i] === null);
      //console.log(isNaN(obj[i]))
      console.log(obj[i] === undefined);
      console.log(obj[i] === "");
      //console.log(obj[i].length === 0)
      console.log("vali");
      if (
        obj[i] === null ||
        
        obj[i] === undefined ||
        obj[i] === ""
        //obj[i].length === 0
      ) {

        //pueden quedar en 2 estados si uno los escribe y los borra
        //validar nulos, en este caso toma x undefined
        console.log("Nulo numerico detectado" + " campo:" + i + " valor:" + obj[i]);
        return false;
      }
      if(typeof obj[i] === 'number'){
          
        if(isNaN(obj[i])){
          console.log("Nulo numerico detectado" + " campo:" + i + " valor:" + obj[i]);
          return false;
        }
      }
    }

    return true;
  }

  export function checkLength(newObj, limits) { //{"idOwn": 1, "vendorCode": "iiiiiiiiiii", "vendorName": "c" }
    /*
    for (var key in obj) {
        if (obj[key] !== null && obj[key] != "")
            return false;
    }
    */
    console.log("length");
    console.log(newObj);
    console.log(limits)
    console.log("length");

    for(var field in newObj){
      console.log("campo:"+ newObj[field] +" maxChar:" + limits[field] + " charActual:" + String(newObj[field]).length)
      if(String(newObj[field]).length > limits[field]) {
        return false
      }
    }
    return true

    /*
    //if(obj.idOwn){ if (obj.idOwn.length > 20 )}
    if (obj.vendorCode) {
      if (obj.vendorCode.length > 20) {
        console.log("!!!!");
        return true;
      }
    }
    if (obj.vendorName) {
      if (obj.vendorName.length > 60) {
        return true;
      }
    }
    if (obj.address1) {
      if (obj.address1.length > 60) {
        return true;
      }
    }
    if (obj.address2) {
      if (obj.address2.length > 60) {
        return true;
      }
    }
    if (obj.phone) {
      if (obj.phone.length > 20) {
        return true;
      }
    }
    if (obj.fax) {
      if (obj.fax.length > 20) {
        return true;
      }
    }
    if (obj.email) {
      if (obj.email.length > 100) {
        return true;
      }
    }
    if (obj.SpecialField1) {
      if (obj.SpecialField1.length > 20) {
        return true;
      }
    }
    if (obj.SpecialField2) {
      if (obj.SpecialField2.length > 20) {
        return true;
      }
    }
    if (obj.SpecialField3) {
      if (obj.SpecialField3.length > 20) {
        return true;
      }
    }
    if (obj.SpecialField4) {
      if (obj.SpecialField4.length > 20) {
        return true;
      }
    }

    return false;
    {
      //pueden quedar en 2 estados si uno los escribe y los borra
      //validar nulos, en este caso toma x undefined
      console.log("falso nulo");
      return false;
    }
    return true;
    */
  }
  export default {checkLength, checkNulls}