import { createStore } from 'redux';


const dialogInfoReducer = (state = { dialogInfoStatus: false, dialogInfoMessage: "vacio"}, action) => {
    if(action.type === 'activar' ){
        console.log("activando en redux");
        return{
            dialogInfoStatus: true,
            dialogInfoMessage: action.message //recibo parametro con mensaje por action
        }
    }

    if(action.type === 'desactivar' ){
        console.log("desactivando en redux");
        return{
            dialogInfoStatus: false,
        }
    }
    return state;
};

const store = createStore(dialogInfoReducer);
export default store;