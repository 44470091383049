import React, { useState, /*useEffect*/ } from "react";
import classes from "./Vendor.module.css";
import VendorTable from "./VendorTable";
import Dialogo from "../../../components/UI/DialogInfo";

/*
interface IMessage {
  message: string;
}*/

function Vendor() {
  const [/*message*/, setMessage] = useState<string>();

  console.log("Render Vendor");

  return (
    <div className="classes">
      <Dialogo />
      <div className={classes.Titulo}>
        <div className={classes.Card}>
          <h1 >Maestros</h1>
          <h4 >Registros de proveedores</h4>
        </div>
      </div>


      <VendorTable setMessage={setMessage} />
    </div>
  );
}

export default Vendor;
