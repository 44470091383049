//importes react
import React, { /*useState, useEffect,*/ Fragment } from "react";
////import { useSelector, useDispatch } from "react-redux";
import {
  Route,
  BrowserRouter,
  /*Link, NavLink,*/ Switch,
} from "react-router-dom";
//importes componentes
import "./App.css";
import VendorMaestros from "./pages/Maestros/Vendor/Vendor";
import OwnerMaestros from "./pages/Maestros/owner/Owner";
import CustomerMaestros from "./pages/Maestros/customer/Customer";
import DeviceMaestros from "./pages/Maestros/device/Device";
import UomTypeMaestros from "./pages/Maestros/uomType/UomType";
import ItemUomMaestros from "./pages/Maestros/itemUom/ItemUom";
import Item from "./pages/Maestros/item/Item";
import Layout from "./components/Layouts/Layout";
import Landing from "./pages/Home/Landing";
//importes material ui
import customTheme from "./customTheme";
import { ThemeProvider } from "@mui/material/styles";
import CreateReceipt from "./pages/Receipt/create-receipt";

console.log("V1.0.2");
console.log("V1.0.3");

/*
const App: React.FC<{ message: string }> = ({ message }) => (
  <div>{message}</div>
);

const Component1 = ({ prop1, prop2 }): JSX.Element => {  }
*/
//function App() {
const App: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <Fragment>
        <BrowserRouter>
          <Switch>
            <Route path="/vendor" exact>
              <Fragment>
                <div className="Vendor">
                  <Layout>
                    <VendorMaestros />
                  </Layout>
                </div>
              </Fragment>
            </Route>
            <Route path="/owner" exact>
              <Fragment>
                <div className="Owner">
                  <Layout>
                    <OwnerMaestros />
                  </Layout>
                </div>
              </Fragment>
            </Route>
            <Route path="/customer" exact>
              <Fragment>
                <div className="Customer">
                  <Layout>
                    <CustomerMaestros />
                  </Layout>
                </div>
              </Fragment>
            </Route>
            <Route path="/receipt" exact>
              <Fragment>
                <div className="CreateReceipt">
                  <Layout>
                    <CreateReceipt />
                  </Layout>
                </div>
              </Fragment>
            </Route>
            <Route path="/device" exact>
              <Fragment>
                <div className="Device">
                  <Layout>
                    <DeviceMaestros />
                  </Layout>
                </div>
              </Fragment>
            </Route>
            <Route path="/UomType" exact>
              <Fragment>
                <div className="UomType">
                  <Layout>
                    <UomTypeMaestros />
                  </Layout>
                </div>
              </Fragment>
            </Route>

            <Route path="/ItemUom" exact>
              <Fragment>
                <div className="ItemUom">
                  <Layout>
                    <ItemUomMaestros />
                  </Layout>
                </div>
              </Fragment>
            </Route>

            <Route path="/Item" exact>
              <Fragment>
                <div className="Item">
                  <Layout>
                    <Item />
                  </Layout>
                </div>
              </Fragment>
            </Route>

            <Route path="/" exact>
              <Fragment>
                <div className="Landing">
                  <Layout>
                    <Landing />
                  </Layout>
                </div>
              </Fragment>
            </Route>
            <Route path="*">
              <Fragment>
                <div className="Landing">
                  <Layout>
                    <Landing />
                  </Layout>
                </div>
              </Fragment>
            </Route>
          </Switch>
        </BrowserRouter>
        <div>
          <h3>WMSTek4</h3>
        </div>
      </Fragment>
    </ThemeProvider>
  );
};

export default App;
