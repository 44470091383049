import React, { /*useState ,*/ useEffect } from "react";
import { /*Link,*/ NavLink, useLocation } from "react-router-dom";
////import classes from "./SidePanel.module.css";
//import { useSelector, useDispatch } from "react-redux";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
//import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
//import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
//import DraftsIcon from "@mui/icons-material/Drafts";
//import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import HomeIcon from "@mui/icons-material/Home";

type Props = {
  sidePanelOpen: boolean,
  sidePanelOpenToggle: React.MouseEventHandler<HTMLDivElement>
};

export default function SidePanel(props: Props) {
  /*
  const [path, setPath] = useState("");

  let location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
    console.log("ruta");
    console.log(location.pathname);
    console.log("ruta");
  }, [location, setPath]);

  const activeRoute = (route) => {
    console.log("ruta");
    console.log(route, path);
    console.log("ruta");
    return route === path;
  };

*/

  const { pathname } = useLocation();

  const rutasMaestros = [
    { nombre: "Proveedores", ruta: "/vendor" },
    { nombre: "Dueños", ruta: "/owner" },
    { nombre: "Clientes", ruta: "/customer" },
    { nombre: "Dispositivos", ruta: "/device" },
    { nombre: "Unidades de medida", ruta: "/UomType" },
    { nombre: "Items", ruta: "/item" },
    //{ nombre: "Ubicaciones", ruta: "/location" },
    //{ nombre: "Bodegas", ruta: "/warehouse" },
    { nombre: "Presentaciones", ruta: "/ItemUom" },
  ];

  console.log("ruta");
  console.log(
    "PN:" + pathname //+ " miRuta:/vendor bool:" + (pathname === "/vendor")
  );
  console.log("ruta");

  //const dialogInfoStatus = useSelector((state) => state.dialogInfoStatus);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    console.log("abriendo/cerrando subcategoria")
    setOpen(!open);

  };

  //primera carga, simula onmount
  useEffect(() => {
    if (rutasMaestros.filter((ele) => ele.ruta === pathname).length > 0) {
      setOpen(true);
    }
  }, []);
/*
  const [state, setState] = React.useState({
    left: props.sidePanelOpen,

    //top: false,
    //bottom: false,
    //right: false,
  });
*/
/*
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    //setState({ ...state, [anchor]: open });
  };*/

  const list = (anchor: any) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
      className="Secondary"
      /*
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      */
    >
      <List>
        {/*Object.keys(routes).map((routeName, index) => {
      const route = routes[routeName];
  
      return (
        <ListItem selected={route === pathname} button key={route}>
          <ListItemText primary={routeName} />
        </ListItem>
      );
    })*/}

        <NavLink /* activeClassName={"HighlightBorder.active"} */ to="/">
          <ListItem
            onClick={props.sidePanelOpenToggle}
            button
            selected={pathname === "/"}
            className={(pathname === "/") ? "Info" : ""}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Inicio" />
          </ListItem>
        </NavLink>

        <ListItemButton onClick={handleClick}>
          <ListItemIcon>
            <InboxIcon />
          </ListItemIcon>
          <ListItemText primary="Maestros" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {rutasMaestros.map((ruta) => {
            return (
              <List component="div" disablePadding key={ruta.ruta}  >
                <NavLink to={ruta.ruta}>
                  <ListItemButton onClick={props.sidePanelOpenToggle}
                    sx={{ pl: 4 }}
                    selected={ruta.ruta === pathname}
                    className={ruta.ruta === pathname && "Info"}
                  >
                    <ListItemIcon>
                      <StarBorder />
                    </ListItemIcon>
                    <ListItemText primary={ruta.nombre} />
                  </ListItemButton>
                </NavLink>
              </List>
            );
          })}
        </Collapse>
      </List>

      <Divider />
      <List>
        {["Administracion", "Entradas", "Salidas"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {["Documentos", "KPI", "Reportes"].map((text, index) => (
          <ListItem button key={text}>
            <ListItemIcon>
              {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
            </ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {["left"].map((anchor: any) => (
        <React.Fragment key={anchor}>
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer
            anchor={anchor}
            open={props.sidePanelOpen}
            //onClose={toggleDrawer(anchor, false)}
            onClose={props.sidePanelOpenToggle}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
