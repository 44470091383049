//importes react
import React, { useState } from "react";
//importes componentes
////import classes from "./MainHeader.module.css";
import SidePanel from "./SidePanel";
//importes material-ui
import Button from "@mui/material/Button";
import Typography from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
/* import Image from "@mui/icons-material/Image"; */
import AccountCircle from "@mui/icons-material/AccountCircle";
import {useLocation } from "react-router-dom";
import Auth from './Auth'


const Header = React.memo(() => {

  const { pathname } = useLocation();
  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const sidePanelOpenToggle = () => {
    setSidePanelOpen(!sidePanelOpen);
  };

  const [openAuth, setOpenAuth] = React.useState(false);

  const handleClickOpenAuth = () => {
    console.log("abriendo form auth")
    setOpenAuth(true);
    console.log(openAuth)
  };

  const handleCloseAuth = (event : boolean) => {
    console.log("cerrando auth")
    setOpenAuth(false);
  };

  return (
    <div>
      <SidePanel
        sidePanelOpen={sidePanelOpen}
        sidePanelOpenToggle={sidePanelOpenToggle}
      />
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <Box >
        <AppBar position="static" className={"Primary"}>
          <Toolbar>
            <Grid container>
              <Grid item xs={12} md={5}>
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={sidePanelOpenToggle}
                >
                  <MenuIcon />
                  Menu
                </IconButton>
                <Button color="secondary" variant="contained">Ruta {pathname}</Button>
              </Grid>
              <Grid item xs={12} md={5}>
                <Typography  component="div">
                  <Box
                    component="img"
                    sx={{
                      height: 45,
                      width: 170,
                      /*maxHeight: { xs: 150, md: 80 },
  maxWidth: { xs: 175, md: 125 },*/
                    }}
                    alt="WMSTek"
                    //src="https://images.unsplash.com/photo-1512917774080-9991f1c4c750?auto=format&w=350&dpr=2"
                    src={require("../../assets/menu_logo.png")}
                  />
                </Typography>
              </Grid>
              <Grid item xs={12} md={2}>
                <IconButton
                  onClick={handleClickOpenAuth}
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Button color="inherit" onClick={handleClickOpenAuth}>Iniciar Sesión</Button>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
      


      <Auth open={  openAuth } handleCloseAuth={ handleCloseAuth}></Auth>              
    </div>
  );
});
export default Header;
