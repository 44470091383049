import React /* MouseEventHandler */ /*,{Fragment}*/ from "react";
//import { SnackbarProvider } from "notistack";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Container from "@mui/material/Container";
import classes from "./AddForm.module.css";
import Clients from "./Clients";

import Grid from "@mui/material/Grid";
//import Box from "@mui/material/Box";

import { useSnackbar } from "notistack";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

import { Formik, useFormik } from "formik";
import * as Yup from "yup";

type Props = {
  handleCloseAddForm: any;
  open: boolean;
};

const validationSchema = Yup.object().shape({
  /*
  email: Yup.string()
    .email("Ingresa un Email valido")
    .required("El Email es requerido"),
  password: Yup.string()
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .required("La contraseña es requerida"),
*/
//yup.string().length(5)
    //conversionFactor:Yup.number().required().positive().integer().min(1, 'Debe tener mas de 1 caracter').max(30, 'Debe tener menos de 30 caracteres'),
    

    //fecha: date().transform(parseDateString).max( new Date()),
    item: Yup.object().shape({
      code: Yup.string()
      .required()
      .min(1, 'Debe tener mas de 1 caracter')
      .max(30, 'Debe tener menos de 30 caracteres'),
    }),
  
    conversionFactor: Yup.number()
    .required()
    .positive()
    .min(1, 'Debe tener mas de 1 caracter')
    .max(99999999999, 'Debe tener menos de 30 caracteres'),
    
    barCode: Yup.string()
    .required()
    .min(1, 'Debe tener mas de 1 caracter')
    .max(30, 'Debe tener menos de 30 caracteres'),

    lenght: Yup.number()
    .positive()
    .min(1, 'Debe tener mas de 1 caracter')
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    width: Yup.number()
    .positive()
    .min(1, 'Debe tener mas de 1 caracter')
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    height: Yup.number()
    .positive()
    .min(1, 'Debe tener mas de 1 caracter')
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    volume: Yup.number()
    .positive()
    .min(1, 'Debe tener mas de 1 caracter')
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    weight: Yup.number()
    .positive()
    .min(1, 'Debe tener mas de 1 caracter')
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    length: Yup.number()
    .positive()
    .min(1, 'Debe tener mas de 1 caracter')
    .max(99999999999, 'Debe tener menos de 12 numeros'),


    status: Yup.boolean(),
   
    layoutUomQty: Yup.number()
    .positive()
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    layoutUnitQty: Yup.number()
    .positive()
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    layoutMaxWeightUpon: Yup.number()
    .positive()
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    putAwayZone: Yup.string()
    .max(10, 'Debe tener menos de 10 caracteres'),

    pickArea: Yup.string()
    .max(10, 'Debe tener menos de 10 caracteres'),

    specialField1: Yup.string()
    .max(20, 'Debe tener menos de 20 numeros'),

    specialField2: Yup.string()
    .max(20, 'Debe tener menos de 20 numeros'),

    specialField3: Yup.string()
    .max(20, 'Debe tener menos de 20 numeros'),

    specialField4: Yup.string()
    .max(20, 'Debe tener menos de 20 numeros'),

    dateModified: Yup.date(),

    userModified: Yup.string()
    .max(20, 'Debe tener menos de 20 numeros'),

    idItem: Yup.number()
    .min(1)
    .positive()
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    idUomType: Yup.number()
    .min(1)
    .positive()
    .max(99999999999, 'Debe tener menos de 12 numeros'),

    dateCreated: Yup.date(),

    uomName: Yup.string().
    min(1)
    .max(20, 'Debe tener menos de 20 numeros'),
/*
    length: number;
    width: number;
    height: number;
    volume: number;
    weight: number;
    status: boolean;
    layoutUomQty: number;
    layoutUnitQty: number;
    layoutMaxWeightUpon: number;
    putawayZone: string;
    pickArea: string;
    specialField1: string;
    specialField2: string;
    specialField3: string;
    specialField4: string;
    dateCreated: Date;
    userCreated: string;
    dateModified: Date;
    userModified: string;
    idItem: number;
    idUomType: number;
    uomName: string;
 
    item: {
        code: string | number
    }; 

*/


});
const AddForm = (props: Props) => {
  const formik = useFormik({
    
    initialValues: {
      conversionFactor: "",
      length: "",
      width: "",
      height: "",
      volume: "",
      weight: "",
      status: "",
      layoutUomQty: "",
      layoutUnitQty: "",
      layoutMaxWeightUpon: "",
      putawayZone: "",
      pickArea: "",
      specialField1: "",
      specialField2: "",
      specialField3: "",
      specialField4: "",
      dateCreated: "",
      userCreated: "",
      dateModified: "",
      userModified: "",
      idItem: "",
      idUomType: "",
      uomName: "",
      barCode: "",
   
      item: {
          code: ""
      }
    },

    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  /*
  console.log("props");
  console.log(typeof props.handleCloseAddForm);
  console.log("props");
*/
  const { enqueueSnackbar /* closeSnackbar */ } = useSnackbar();

  const handleClickSnackbar = (mensaje: string) => {
    enqueueSnackbar(mensaje, {
      variant: "success",
    });
  };

  return (
    <div >
      {/*       <Button variant="outlined" onClick={props.handleClickOpenAddForm}>
        Open form dialog
      </Button> */}

      {/*  id: number;
 
    conversionFactor: number;
    barCode: string;
    length: number;
    width: number;
    height: number;
    volume: number;
    weight: number;
    status: boolean;
    layoutUomQty: number;
    layoutUnitQty: number;
    layoutMaxWeightUpon: number;
    putawayZone: string;
    pickArea: string;
    specialField1: string;
    specialField2: string;
    specialField3: string;
    specialField4: string;
    dateCreated: Date;
    userCreated: string;
    dateModified: Date;
    userModified: string;
    idItem: number;
    idUomType: number;
    uomName: string;
 
    item: {
        code: string | number
    }; */}

      <Dialog open={props.open} onClose={props.handleCloseAddForm}>
        <DialogTitle className={"Info"}>Nuevo Elemento</DialogTitle>
        <DialogContent className={"Secondary"}>
          <DialogContentText >
            Complete los campos y presione ingresar
          </DialogContentText>

          <Container maxWidth="lg" className={(classes.main, "White")}>
            <FormControl>
              <Grid container spacing={2} >
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={<Checkbox defaultChecked color="primary" />}
                      label="Activo"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="item.code"
                    name="item.code"
                    label="Código"
                    value={formik.values.item.code}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.item?.code && Boolean(formik.errors.item?.code)}
                    helperText={formik.touched.item?.code && formik.errors.item?.code}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="conversionFactor"
                    name="conversionFactor"
                    label="Factor de Conversión"
                    type="number"
                    value={formik.values.conversionFactor}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.conversionFactor && Boolean(formik.errors.conversionFactor)}
                    helperText={formik.touched.conversionFactor && formik.errors.conversionFactor}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="barCode"
                    name="barCode"
                    label="Código de barras"
                    value={formik.values.barCode}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.barCode && Boolean(formik.errors.barCode)}
                    helperText={formik.touched.barCode && formik.errors.barCode}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="idUomType"
                    name="idUomType"
                    label="Unidad de Medida"
                    value={formik.values.idUomType}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.idUomType && Boolean(formik.errors.idUomType)}
                    helperText={formik.touched.idUomType && formik.errors.idUomType}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="length"
                    name="length"
                    label="Largo"
                    value={formik.values.length}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.length && Boolean(formik.errors.length)}
                    helperText={formik.touched.length && formik.errors.length}
                  />
                </Grid>


                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="width"
                    name="width"
                    label="Ancho"
                    value={formik.values.width}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.width && Boolean(formik.errors.width)}
                    helperText={formik.touched.width && formik.errors.width}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="height"
                    name="height"
                    label="Alto"
                    value={formik.values.height}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.height && Boolean(formik.errors.height)}
                    helperText={formik.touched.height && formik.errors.height}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="volume"
                    name="volume"
                    label="Volumen"
                    value={formik.values.volume}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.volume && Boolean(formik.errors.volume)}
                    helperText={formik.touched.volume && formik.errors.volume}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="weight"
                    name="weight"
                    label="Peso"
                    value={formik.values.weight}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.weight && Boolean(formik.errors.weight)}
                    helperText={formik.touched.weight && formik.errors.weight}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="layoutUomQty"
                    name="layoutUomQty"
                    label="Max presentaciones por pallet"
                    value={formik.values.layoutUomQty}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.layoutUomQty && Boolean(formik.errors.layoutUomQty)}
                    helperText={(formik.touched.layoutUomQty && formik.errors.layoutUomQty) ? "" : "Error"} 
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="layoutUnitQty"
                    name="layoutUnitQty"
                    label="Max Cantidad apilada"
                    value={formik.values.layoutUnitQty}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.layoutUnitQty && Boolean(formik.errors.layoutUnitQty)}
                    helperText={formik.touched.layoutUnitQty && formik.errors.layoutUnitQty}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    size="small"
                    id="layoutMaxWeightUpon"
                    name="layoutMaxWeightUpon"
                    label="Max Peso apilado"
                    value={formik.values.layoutMaxWeightUpon}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    error={formik.touched.layoutMaxWeightUpon && Boolean(formik.errors.layoutMaxWeightUpon)}
                    helperText={formik.touched.layoutMaxWeightUpon && formik.errors.layoutMaxWeightUpon}
                  />
                </Grid>

                
              </Grid>

              {/*                 <Button
                  color="primary"
                  variant="contained"
                  fullWidth
                  type="submit"
                >
                  Submit
                </Button> */}
            </FormControl>

            {/* <FormControl>
              <Grid container spacing={2} className={classes.cuadro}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="user"
                    label="Usuario"
                    fullWidth
                    variant="standard"
                    required
                    // error
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="password"
                    label="Contraseña"
                    type="Password"
                    // fullWidth 
                    variant="standard"
                    required
                    fullWidth
                    className={classes.cuadro}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Clients></Clients>
                </Grid>
              </Grid>
            </FormControl> */}
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseAddForm}>Cancelar</Button>
          <Button
            color="success"
            variant="contained"
            onClick={() => {
              formik.handleSubmit();
              //handleClickSnackbar("Autenticación exitosa");
              //props.handleCloseAddForm();
            }}
          >
            Ingresar
          </Button>
          {/*           
            <Button
            onClick={() => {
              formik.handleSubmit()
              handleClickSnackbar("Autenticación exitosa");
              props.handleCloseAddForm();
            }}
          >
            Ingresar
          </Button> 
          */}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AddForm;
