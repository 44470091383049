import React from "react";
import classes from "./Summary.module.css";

const Summary = () => {
  return (
    <section className={classes.summary}>
      <h2>WMSTek 4</h2>

      <p>
        Centraliza y controla los procesos logísticos de tu centro de
        distribución de manera eficiente.
      </p>
      <p>
        Sin importar el tamaño de almacén o tipo de industria donde te
        encuentres, aumenta el control y precisión de los procesos.
      </p>
    </section>
  );
};

export default Summary;
