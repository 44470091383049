const porDefecto : any = [
  {
    "id": 1,
    "code": "PLACEHOLDER",
    "conversionFactor": "5.0000",
    "barCode": "5",
    "uomName": "5",
    "length": "0.0000",
    "width": "0.0000",
    "height": "0.0000",
    "volume": "0.0000",
    "weight": "0.0000",
    "status": false,
    "layoutUomQty": 0,
    "layoutUnitQty": 0,
    "layoutMaxWeightUpon": "0.0000",
    "putawayZone": null,
    "pickArea": null,
    "specialField1": "ED1",
    "specialField2": null,
    "specialField3": null,
    "specialField4": null,
    "dateCreated": null,
    "userCreated": null,
    "dateModified": null,
    "userModified": null,
    "idItem": 1,
    "idUomType": 5
}
  ];



export default porDefecto