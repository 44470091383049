import React, { useState, useEffect/*,  forwardRef */ } from "react";
import { /*useSelector,*/ useDispatch } from "react-redux";
import MaterialTable/*, {Icons}*/ from "@material-table/core";
import classes from "./ItemUom.module.css";
import porDefecto from "./ItemUomUtils";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Dialogo from "../../../components/UI/DialogInfo";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"; //iconos customizados
import {
  restGet,
  restGetColumn,
  restPost,
  restDelete,
  restUpdate,
} from "../../../respository/RESTAPI";
import { useSnackbar } from "notistack";
import { checkNulls, checkLength } from "../../../validations/validateInput";
//import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from "@mui/material/LinearProgress";
import { AxiosResponse } from "axios";
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox'

//Importe formulario
//import Form from '../../../components/Layouts/Form'
import Form from './Form/AddForm'
//Importe iconos
import AddBoxIcon from '@mui/icons-material/AddBox';
import ItemUom from '../../../dto/item-uom.dto'

//Macros
const REQUIRED_FIELDS = [
  "code",
  "conversionFactor",
  "barCode",
  "idUomType",
  "uomName",
  "status",
];
const MAXLENGHT_FIELDS = {
  //if(obj.idOwn){ if (obj.idOwn.length > 20 )
  id: 12,
  code: 20,
  conversionFactor: 12,
  barCode: 30,
  length: 12,
  width: 12,
  height: 12,
  volume: 12,
  weight: 12,
  status: 6,
  layoutUomQty: 20,
  layoutUnitQty: 20,
  layoutMaxWeightUpon: 12,
  putawayZone: 10,
  pickArea: 10,
  specialField1: 20,
  specialField2: 20,
  specialField3: 20,
  specialField4: 20,
  dateCreated: 60,
  userCreated: 20,
  dateModified: 60,
  userModified: 20,
  idItem: 20,
  idUomType: 20,
  uomName: 20,
};
const url1 =
  //"https://jsonplaceholder.typicode.com/todos/1"
  //"http://localhost:3000/item-uoms";
  "http://localhost:3000/item-uoms";
//"http://localhost:3000/item-uoms?filter[include][]=item&filter[include][][relation]=uomtype";
//"https://api-dev.stglatam.app/ms/v1/maestros/vendors"
//"/ms/v1/maestros/vendors";
//"http://stapi.co/api/v1/rest/Elementos/search";
var url2 = "http://localhost:3000/items";
var url3 =
  "http://localhost:3000/item-uoms?filter[include][]=item&filter[include][][relation]=uomtype";
/**************/

type Props = {
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

//Funcion principal
const ItemUomTable = React.memo(function VendorTable(props: Props) {
  //React inits
  
  const [data, setData] = useState<ItemUom[]>();
  //const [dataOwners, setDataOwners] = useState();
  const [dataItemLookup, setdataItemLookup] = useState<object>();
  const [filtering, setFiltering] = useState(true);
  const [grupingToggle, setGrupingToggle] = useState(false);
  const { enqueueSnackbar /*, closeSnackbar*/ } = useSnackbar();
  const dispatch = useDispatch();
  const activarDialogInfoHandler = (messageParam: string) => {
    dispatch({ type: "activar", message: messageParam });
  };
  const [loadingState, setLoadingState] = useState(false);

  const [openForm, setOpenForm] = React.useState(false);

  const handleClickOpenAddForm = () => {
    console.log("abriendo form Form")
    setOpenForm(true);
    console.log(openForm)
  };

  const handleCloseAddForm = (event : boolean) => {
    console.log("cerrando Form")
    setOpenForm(false);
  };

  
const addHandler = () => {
  alert("typescript")
}

  /*
  const desactivarDialogInfoHandler = () => {
    dispatch({ type: "desactivar" });
  };
  */

  //primera carga, simula onmount
  useEffect(() => {
    //placeholder
    setData(porDefecto);
    //REST get vendors
    getRest();
    getRestObj();
  }, []);

  /*****************************/
  /****       CRUD          ****/
  /*****************************/
  //REST calls
  ////GET
  const getRest = async () => {
    setLoadingState(true);
    const x = await restGet(url3, "GET");
    setLoadingState(false);
    setData(x.data);

    //configuracion foraneos
    var lista: any = {};
    x.data.map((o: any) => {
      /*
      lista[o.id] = o[objectGet];
      return o[objectGet]*/
      lista[o.item.id] = o.item["code"];
    });
    console.log("xdata");
    console.log(lista);
    console.log("xdata");
  };
  //GET Column
  const getRestObj = async () => {
    setLoadingState(true); //elemento grafico de carga
    const x = await restGetColumn(url2, "code");
    setLoadingState(false); //elemento grafico de carga
    setdataItemLookup(x);
    console.log("column");
    console.log(x);
  };
  //CREATE
  const postRest = async (elemento: ItemUom) => {
    console.log("post previa");
    console.log(elemento);

    if (elemento.weight) elemento.weight = +elemento.weight;
    if (elemento.idItem) elemento.idItem = +elemento.idItem;
    if (elemento.length) elemento.length = +elemento.length;
    if (elemento.width) elemento.width = +elemento.width;
    if (elemento.height) elemento.height = +elemento.height;
    if (elemento.volume) elemento.volume = +elemento.volume;
    if (elemento.layoutUnitQty)
      elemento.layoutUnitQty = +elemento.layoutUnitQty;
    if (elemento.layoutUomQty) elemento.layoutUomQty = +elemento.layoutUomQty;
    if (elemento.layoutMaxWeightUpon)
      elemento.layoutMaxWeightUpon = +elemento.layoutMaxWeightUpon;
    //elemento.idOwn = +elemento.idOwn;
    //elemento.idItem = +elemento.idItem;
    //elemento.status = Boolean(elemento.status);
    elemento.conversionFactor = +elemento.conversionFactor;
    elemento.idUomType = +elemento.idUomType;

    console.log("post previa");
    console.log(elemento);
    setLoadingState(true);
    const x = await restPost(url1, elemento);
    setLoadingState(false);
    if (x !== null && x !== undefined) {
      if (x.status === 200) {
        enqueueSnackbar("Elemento ingresado exitosamente", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error al ingresar elemento", {
          variant: "error",
        });
      }
    }
    getRest();
  };
  //DELETE
  const deleteRest = async (elemento: ItemUom) => {
    console.log("borrando");
    console.log(elemento);
    console.log("borrando");

    const { id } = elemento;
    setLoadingState(true);
    const x = await restDelete(url1, id);
    setLoadingState(false);
    
    if ((x.status = 200)) {
      enqueueSnackbar("Elemento eliminado exitosamente", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Error al eliminado elemento", {
        variant: "error",
      });
    }
    getRest();
  };
  //UPDATE
  const UpdateRest = async (elemento: ItemUom) => {
    if (elemento.weight) elemento.weight = +elemento.weight;
    if (elemento.idItem) elemento.idItem = +elemento.idItem;
    if (elemento.length) elemento.length = +elemento.length;
    if (elemento.width) elemento.width = +elemento.width;
    if (elemento.height) elemento.height = +elemento.height;
    if (elemento.volume) elemento.volume = +elemento.volume;
    if (elemento.layoutUnitQty)
      elemento.layoutUnitQty = +elemento.layoutUnitQty;
    if (elemento.layoutUomQty) elemento.layoutUomQty = +elemento.layoutUomQty;
    if (elemento.layoutMaxWeightUpon)
      elemento.layoutMaxWeightUpon = +elemento.layoutMaxWeightUpon;
    //elemento.status = Boolean(elemento.status);
    if (elemento.conversionFactor)
      elemento.conversionFactor = +elemento.conversionFactor;
    if (elemento.idUomType) elemento.idUomType = +elemento.idUomType;
    //reconvercion explicita ya que lookup x ser input los deja en string
    //elemento.idOwn = +elemento.idOwn;
    console.log("update");
    console.log("ele:" + elemento + "url:" + url1 + "id:" + elemento.id);
    console.log("update");
    setLoadingState(true);
    const x: AxiosResponse<any> = await restUpdate(elemento, url1, elemento.id);
    setLoadingState(false);

    if (x !== null && x !== undefined) {
      if (x.status === 204) {
        enqueueSnackbar("Elemento modificado exitosamente", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error al modificar elemento", {
          variant: "error",
        });
      }
    }
    getRest();
  };

  console.log("Render VendorTable");

  console.log("para lookup");

  console.log("para lookup");
  console.log(data);
  const columns = [
    {
      title: "ID",
      field: "id",
      defaultSort: "asc",
      /*sorting: false*/ editable: "never",
      cellStyle: { color: "blue" },
      //headerStyle: {color: "purple"}
    }, //orden por defecto o desabilitar orden y edicion
    {
      title: "Código",
      field: "code",

      //validate: (rowData) => (rowData.idOwn == null ? "*Requerido" : ""),
      //render: (rowData) => getOwnerName(rowData.idOwn), ///*** fucnional pero no requerido xk lo hace lookup */
      //lookup: dataItemLookup,
      //defaultGroupOrder:0 //agrupa este campo por defecto, con valor 1 se desabilita el agrupamiento
      //hiddenByColumnsButton: true,
      validate: (rowData: ItemUom) => {
        if (rowData.code === undefined /*|| rowData.idOwn === ""*/) {
          return "*Requerido";
        }
      },

      //validate: (rowData) => (rowData.idOwn == null ? "Requerido" : ""),
    },
    {
      title: "Factor conversión",
      field: "conversionFactor",
      type: "numeric",
      validate: (rowData: ItemUom) => {
        if (
          rowData.conversionFactor === undefined ||
          isNaN(rowData.conversionFactor)
        ) {
          return "*Requerido";
        }
      },
    },
    {
      title: "Cód. Barra",
      field: "barCode",
      validate: (rowData: ItemUom) => {
        if (rowData.barCode === undefined || rowData.barCode === "") {
          return "*Requerido";
        } else {
          if (rowData.barCode.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.barCode.length > 30) {
            return "Debe conterner menos de 30 caracteres";
          }
        }
      },
    },

    {
      title: "Activo",
      field: "status",
      required: true,
      type: "boolean",
      hidden: false,
      validate: (rowData: ItemUom) => {
        if (rowData.status === undefined || rowData.status === null) {
          return "*Requerido";
        }
      },
    },
    {
      title: "Tipo UOM",
      field: "idUomType",
      type: "numeric",
      required: true,
      validate: (rowData: ItemUom) => {
        if (rowData.idUomType === undefined || rowData.idUomType === null) {
          return "*Requerido";
        } else {
          if (rowData.idUomType < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.idUomType.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },
    {
      title: "UOM",
      field: "uomName",
      validate: (rowData: ItemUom) => {
        if (rowData.uomName === undefined || rowData.uomName === "") {
          return "*Requerido";
        } else {
          if (rowData.uomName.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.uomName.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Item",
      field: "item.code",
      //lookup: data.item.shortName,
      //lookup: dataItemLookup,
      validate: (rowData: ItemUom) => {
        console.log("row");
        console.log(rowData);
        console.log("rowData");
        if (rowData.item !== undefined && rowData.item !== null) {
          if (
            rowData.item.code === undefined ||
            rowData.item.code === null ||
            rowData.item.code === ""
          ) {
            return "*Requerido";
          } else {
            if (rowData.item.code < 0) {
              return "Debe conterner mas de 0 caracteres";
            }
            if (rowData.item.code.toString().length > 12) {
              return "Debe conterner menos de 12 caracteres";
            }
          }
        } else {
          return "*Requerido";
        }
      },
    },

    // "itemUom", "code", "conversionFactor", "barCode", "idUomType", "uomName", "status"
    {
      title: "Largo",
      field: "length",
      type: "numeric",
      validate: (rowData: ItemUom) => {
        if (rowData.length === undefined /*|| rowData.length === "" */) {
        } else {
          if (rowData.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.length > 999999999999) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },
    {
      title: "Ancho",
      field: "width",
      type: "numeric",
      validate: (rowData: ItemUom) => {
        if (rowData.width === undefined || rowData.width === null) {
        } else {
          if (rowData.width < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.width.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },
    {
      title: "Alto",
      field: "height",
      type: "numeric",
      validate: (rowData: ItemUom) => {
        if (rowData.height === undefined || rowData.height === null) {
        } else {
          if (rowData.height < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.height.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },
    {
      title: "Volumen",
      field: "volume",
      type: "numeric",
      validate: (rowData: ItemUom) => {
        if (rowData.volume === undefined || rowData.volume === null) {
        } else {
          if (rowData.volume < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.volume.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },
    {
      title: "Peso",
      field: "weight",
      validate: (rowData: ItemUom) => {
        if (rowData.weight === undefined || rowData.weight === null) {
        } else {
          if (rowData.weight < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.weight.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },

    {
      title: "Uom Cant.",
      field: "layoutUomQty",
      type: "numeric",
      hidden: true,
      validate: (rowData: ItemUom) => {
        if (
          rowData.layoutUomQty === undefined ||
          rowData.layoutUomQty === null
        ) {
        } else {
          if (rowData.layoutUomQty < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.layoutUomQty.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },
    {
      title: "Unidad Cant.",
      field: "layoutUnitQty",
      hidden: true,
      validate: (rowData: ItemUom) => {
        if (
          rowData.layoutUnitQty === undefined ||
          rowData.layoutUnitQty === null
        ) {
        } else {
          if (rowData.layoutUnitQty < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.layoutUnitQty.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },

    {
      title: "Max Peso Apilado",
      field: "layoutMaxWeightUpon",
      hidden: true,
      validate: (rowData: ItemUom) => {
        if (
          rowData.layoutMaxWeightUpon === undefined ||
          rowData.layoutMaxWeightUpon === null
        ) {
        } else {
          if (rowData.layoutMaxWeightUpon < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.layoutMaxWeightUpon.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },

    {
      title: "Zona putaway",
      field: "putawayZone",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemUom) => {
        if (
          rowData.putawayZone === undefined ||
          rowData.putawayZone === "" ||
          rowData.putawayZone === null
        ) {
          return;
        } else {
          if (rowData.putawayZone.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.putawayZone.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Area Picking",
      field: "pickArea",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemUom) => {
        if (
          rowData.pickArea === undefined ||
          rowData.pickArea === "" ||
          rowData.pickArea === null
        ) {
          return;
        } else {
          if (rowData.pickArea.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.pickArea.length > 10) {
            return "Debe conterner menos de 10 caracteres";
          }
        }
      },
    },
    {
      title: "specialField1",
      field: "specialField1",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: ItemUom) => {
        if (
          rowData.specialField1 === undefined ||
          rowData.specialField1 === "" ||
          rowData.specialField1 === null
        ) {
          return;
        } else {
          if (rowData.specialField1.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.specialField1.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "specialField2",
      field: "specialField2",
      emptyValue: () => <em>No</em>,
      export: false,
      hidden: true,
      validate: (rowData: ItemUom) => {
        if (
          rowData.specialField2 === undefined ||
          rowData.specialField2 === "" ||
          rowData.specialField2 === null
        ) {
          return;
        } else {
          if (rowData.specialField2.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.specialField2.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "specialField3",
      field: "specialField3",
      emptyValue: () => <em>No</em>,
      export: false,
      hidden: true,
      validate: (rowData: ItemUom) => {
        if (
          rowData.specialField3 === undefined ||
          rowData.specialField3 === "" ||
          rowData.specialField3 === null
        ) {
          return;
        } else {
          if (rowData.specialField3.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.specialField3.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "specialField4",
      field: "specialField4",
      emptyValue: () => <em>No</em>,
      export: false,
      hidden: true,
      validate: (rowData: ItemUom) => {
        if (
          rowData.specialField4 === undefined ||
          rowData.specialField4 === "" ||
          rowData.specialField4 === null
        ) {
          return;
        } else {
          if (rowData.specialField4.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.specialField4.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Creación",
      field: "dateCreated",
      type: "datetime",
      hidden: true,
    },
    {
      title: "Fecha Mod.",
      field: "dateModified",
      type: "datetime",
      hidden: true,
    },
  ];

  return (
    <div className="App">
      <Form open={  openForm } handleCloseAddForm={ handleCloseAddForm}></Form>              

      <Dialogo />
      <div className={classes.Titulo}></div>
      <button onClick={() => setFiltering(!filtering)}>
        <span>Filtro Busqueda</span>
      </button>
      <button onClick={() => setGrupingToggle(!grupingToggle)}>
        <span>Filtro Agrupar</span>
      </button>
      {loadingState && <LinearProgress />}
      <MaterialTable
        title="Presentaciones"
        data={data}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsPerPage: "N°",
            labelRowsSelect: "Filas",
          },
          toolbar: {
            nRowsSelected: "{0} Filas Seleccionadas",
            //exportName: "Exportar CSV",
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar",
            addRemoveColumns: "Agrega o quita columnas",
          },
          header: {
            actions: "Acciones",
          },
          body: {
            addTooltip: "Agregar",
            emptyDataSourceMessage: "No hay datos",
            filterRow: {
              filterTooltip: "Filtro",
            },
            editRow: {
              deleteText: "¿Esta seguro que quiere borrar esta fila?",
              saveTooltip: "Guardar",
              cancelTooltip: "Cancelar",
            },
          },
          grouping: {
            placeholder: "Arrastra las etiquetas de las columnas aqui",
          },
        }}
        columns={columns as any}
        editable={{
          /*
          //create
          onRowAdd: (newRow) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (!checkNulls(newRow, REQUIRED_FIELDS)) {
                activarDialogInfoHandler(
                  "No pueden haber campos requeridos vacios"
                );
                console.log("tiene nulos");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else if (!checkLength(newRow, MAXLENGHT_FIELDS)) {
                activarDialogInfoHandler("Infringe maximo caracteres");
                console.log("Infringe maximo caracteres");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else {
                console.log("tabledata");
                console.log(newRow);
                delete newRow["tableData"];
                console.log(newRow);
                
          //newRow.map(r => {
            //console.log(r)
            //const {tableData, ...record} = r;
            //return record;
          //});
           
          ////const {tableData, ...record} = newRow;
          //const{id, idVendor} = newRow;
          //const record =  {id, idVendor}

                console.log("Elemento agregado");
                //const record ={ "vendorName": newRow.vendorName, "idVendor": newRow.idVendor}
                console.log(newRow);
                console.log("Elemento agregado end");
                //setData([...data, record])
                postRest(newRow);
              }
              setTimeout(() => resolve(), 100); //cierra la promesa
            }),*/
          //delete
          onRowDelete: (selectedRow: ItemUom) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (props.setMessage) {
                props.setMessage("Invalid");
                console.log("prop no validado");
                reject();
              }
              const updatedData = [...data];
              console.log(selectedRow.tableData.id);
              updatedData.splice(selectedRow.tableData.id, 1);
              console.log(selectedRow);
              deleteRest(selectedRow);
              setData(updatedData);
              setTimeout(() => {
                props.setMessage("Successfully updated!");
                resolve();
              }, 100);
            }),
          //update
          onRowUpdate: (newRow, oldRow) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (!checkNulls(newRow, REQUIRED_FIELDS)) {
                activarDialogInfoHandler(
                  "No pueden haber campos requeridos vacios"
                );
                console.log("tiene nulos");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else if (!checkLength(newRow, MAXLENGHT_FIELDS)) {
                activarDialogInfoHandler("Infringe maximo caracteres");
                console.log("Infringe maximo caracteres");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else {
                console.log(oldRow, newRow);

                console.log("Updating");
                console.log(oldRow.tableData);
                console.log("Updating");

                const updatedData = [...data];
                updatedData[oldRow.tableData.id] = newRow;
                console.log("PostUpdating");
                console.log(newRow);
                //console.log(updatedData);
                console.log("PostUpdating");

                UpdateRest(newRow);
                //update(updatedData);
                props.setMessage("Successfully updated!");
              }
              setTimeout(() => resolve(), 100); //cierra la promesa
            }),
        }}
        actions={[
          {
            icon: () => <AddBoxIcon />,
            tooltip: "Nuevo",
            onClick: (e, data) => {
              handleClickOpenAddForm()
              //alert("Descargar detalle");
              console.log(data);
              
            },
            
            isFreeAction: true, //toggle para que el boton este en la columna o generalizado
          },
          /*
          {
            icon: () => <InsertDriveFileIcon />,
            tooltip: "Add User",
            isFreeAction: true,
            onClick: (event, rowData) => {
              handleClickOpenAddForm();
            }
          },*/
        ]}
        /*
        icons={{
          Add: forwardRef<SVGSVGElement, {}>((props, ref) =>  <Button onClick={addHandler} color="primary" variant="contained">
          Nuevo
        </Button>)
        }}*/
        onSelectionChange={(selectedRow) => console.log(selectedRow)}
        options={{
          sorting: true,
          //searchText:"GinoMP", //busqueda x defecto
          paging: true,
          pageSize: 20, // cantidad registros inicial
          emptyRowsWhenPaging: false, //borra lineas vacias
          pageSizeOptions: [6, 12, 20, 50, 100], // opcion dropdown cantidad elementos
          filtering: filtering, //habilita busquedas por columna
          //toolbar: true,
          //exportButton: true, //habilita csv pdf

          ////exportFileName: "Maestro_Proveedores", //nombre archivo
          addRowPosition: "first",
          actionsColumnIndex: -1,
          columnsButton: true,
          grouping: grupingToggle,
          rowStyle: (data, index) =>
            index % 2 === 0 ? { background: "#eef" } : null,
          headerStyle: {
            background: "#def",
            fontStyle: "Arial Black",
            fontSize: "1.1em",
          },

          selectionProps: (rowData: ItemUom) => ({
            //disable: rowData.name!=="",  //desabilitar checkbox si campo es vacio
            color: "primary",
          }),
          exportMenu: [
            {
              label: "Exportar PDF",
              exportFunc: (cols, datas) => ExportPdf(cols, datas, "vendorPDF"),
            },
            {
              label: "Exportar CSV",
              exportFunc: (cols, datas) => ExportCsv(cols, datas, "vendorCSV"),
            },
          ],
          selection: true,
          //showSelectAllCheckbox: false,
          //showTextRowsSelected: false,
        }}
      />
    </div>
  );
});

export default ItemUomTable;
