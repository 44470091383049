import React, { /* MouseEventHandler */ /*,{Fragment}*/ } from "react";
//import { SnackbarProvider } from "notistack";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Container from "@mui/material/Container";
import classes from "./Auth.module.css";
import Clients from "./Clients";

import Grid from "@mui/material/Grid";
//import Box from "@mui/material/Box";

import { useSnackbar } from "notistack";
import { FormControl } from "@material-ui/core";

type Props = {
  handleCloseAuth: any;
  open: boolean;
};

const Auth = (props: Props) => {
  /*
  console.log("props");
  console.log(typeof props.handleCloseAuth);
  console.log("props");
*/
  const { enqueueSnackbar, /* closeSnackbar */ } = useSnackbar();

  const handleClickSnackbar = (mensaje: string) => {
    enqueueSnackbar(mensaje, {
      variant: "success",
    });
  };
  return (
    <div>
      {/*       <Button variant="outlined" onClick={props.handleClickOpenAuth}>
        Open form dialog
      </Button> */}
      <Dialog open={props.open} onClose={props.handleCloseAuth}>
        <DialogTitle>Autentificación</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Ingrese sus datos para iniciar sesión
          </DialogContentText>

          <Container maxWidth="lg" className={(classes.main, "Tertiary")}>
            <FormControl>
              <Grid container spacing={2} className={classes.cuadro}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="user"
                    label="Usuario"
                    fullWidth
                    variant="standard"
                    required
                    /* error */
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="dense"
                    id="password"
                    label="Contraseña"
                    type="Password"
                    /* fullWidth */
                    variant="standard"
                    required
                    fullWidth
                    className={classes.cuadro}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Clients></Clients>
                </Grid>
              </Grid>
            </FormControl>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleCloseAuth}>Cancelar</Button>

          <Button
            onClick={() => {
              handleClickSnackbar("Autenticación exitosa");
              props.handleCloseAuth();
            }}
          >
            Ingresar
          </Button>
        </DialogActions>
      </Dialog>
{/*       <Button onClick={() => handleClickSnackbar("Snack prueba")}>
        Boton Snack
      </Button> */}
    </div>
  );
};
export default Auth;
