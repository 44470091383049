import React, { Fragment, useState, useEffect } from "react";
import { useFormik } from 'formik';
import * as yup from 'yup';
//import TextField from '@material-ui/core/TextField';
import TextField from '@mui/material/TextField';
//import Button from '@material-ui/core/Button';
import Button from '@mui/material/Button';
import receiptDto from '../../dto/receipt.dto';
import Container from "@mui/material/Container";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import receiptTypeDto from '../../dto/receipt-type.dto';
import { makeStyles } from "@material-ui/core/styles";
import { FormHelperText } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import inboundOrderDTO from '../../dto/inbound-order.dto';
import { DateTime } from "luxon";
import { useSnackbar } from "notistack";
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import { red } from '@mui/material/colors';
import axios, { AxiosError } from 'axios';
import { CustomErrorMSApi } from "../../errors/CustomErrorMSApi";

const dummyReceiptTypes = async () => {

    const list: receiptTypeDto[] = [];

    const nationalType = new receiptTypeDto();
    nationalType.id = 1;
    nationalType.code = 'OCNAC';
    nationalType.name = 'Orden Nacional';
    list.push(nationalType);

    const internationalType = new receiptTypeDto();
    internationalType.id = 2;
    internationalType.code = 'OCINT';
    internationalType.name = 'Orden Internacional';
    list.push(internationalType);

    const prom = new Promise<receiptTypeDto[]>((resolve, reject) => {
        setTimeout(() => {
            resolve(list);
        }, 1500);
    });

    return prom;
}

const dummyInboundOrders = async () => {
    const list: inboundOrderDTO[] = [];

    const inboundOrder1 = new inboundOrderDTO();
    inboundOrder1.idInboundOrder = 2;
    inboundOrder1.idWhs = 1;
    inboundOrder1.idOwn = 1;
    inboundOrder1.inboundNumber = '0001';
    inboundOrder1.idInboundType = 1;
    list.push(inboundOrder1);

    const inboundOrder2 = new inboundOrderDTO();
    inboundOrder2.idInboundOrder = 3;
    inboundOrder2.idWhs = 1;
    inboundOrder2.idOwn = 1;
    inboundOrder2.inboundNumber = '0002';
    inboundOrder2.idInboundType = 1;
    list.push(inboundOrder2);

    const inboundOrder3 = new inboundOrderDTO();
    inboundOrder3.idInboundOrder = 1;
    inboundOrder3.idWhs = 2;
    inboundOrder3.idOwn = 2;
    inboundOrder3.inboundNumber = '1234';
    inboundOrder3.idInboundType = 2;
    list.push(inboundOrder3);

    const prom = new Promise<inboundOrderDTO[]>((resolve, reject) => {
        setTimeout(() => {
            resolve(list);
        }, 1500);
    });

    return prom;
}

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120
    },
    menuItem: {
      display: 'block'
    },
    selectFormControl: {
      padding: '18px 1px 1px'
    },
    labelSelect: {
      margin: '8px'  
    },
    itemForm: {
      paddingBottom: theme.spacing(2)
    },
    messageError: {
        color: red[500],
    }
}));

const validationSchema = yup.object({ 
    inboundOrderNumber: yup
      .string()
      .required('N° Orden Entrada es requerido'),
    receptionType: yup
      .number()
      .required('Tipo Recepción es requerido')
      .positive('Debe seleccionar un Tipo Recepción')
      .integer(),
    location: yup
      .string()
      .min(2, 'Codigo CD debe tener minimo 2 caracteres'),
    receiptDate: yup
      .date()
      .required('Fecha recepción es requerida')
      .typeError('Fecha recepción es requerida'),
    forklift: yup
      .string()
      .min(2, 'Máquina debe tener minimo 2 caracteres'),
});

const ReceiptForm  = () => {

    const { enqueueSnackbar } = useSnackbar();

    const postReceipt = async (newReceipt: receiptDto) => {     
        try {
            await axios.post('http://localhost:3001/receipts', newReceipt);
            enqueueSnackbar('Recepción Enviada', {
                variant: "success",
            });
        } catch (error) {
            const err = error as AxiosError;
            if (err.response) {
                const customError = err.response.data as CustomErrorMSApi
                enqueueSnackbar(`Error. ${customError.error.message}`, {
                    variant: "error",
                });
            } 
        }
    }

    const formik = useFormik({
        initialValues: {
            location: '',
            receptionType: 0,
            inboundOrderNumber: '',
            receiptDate: null,
            forklift: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log(JSON.stringify(values, null, 2));

            //const receiptDateTime = DateTime.fromISO(values.receiptDate);
            //receiptDateTime.toJSDate();

            const newReceipt = new receiptDto();
            newReceipt.idInboundOrder = selectedInboundOrder.idInboundOrder;
            newReceipt.receiptDate = values.receiptDate;
            newReceipt.status = true;
            newReceipt.idWhs = selectedInboundOrder.idWhs;
            newReceipt.idType = values.receptionType;
            newReceipt.idLocationStage = values.location; 
            newReceipt.idLocationForkLift = values.forklift; 
            newReceipt.dateCreated = DateTime.now().toJSDate();
            newReceipt.userCreated = "WEB"; //TODO    
            newReceipt.userWms = "BASE"; //TODO 

            console.log('newReceipt');
            console.log(JSON.stringify(newReceipt, null, 2));

            formik.resetForm();
            setSelectedInboundOrder(null);
            postReceipt(newReceipt);
            
        },
    });

    const cssClasses = useStyles();

    const [receiptTypes, setReceiptTypes] = useState<receiptTypeDto[]>([]);
    const [selectedInboundOrder, setSelectedInboundOrder] = useState<inboundOrderDTO>(null);
    const [filteredInboundOrders, setFilteredInboundOrders] = useState<inboundOrderDTO[]>([]);

    useEffect(() => {
        const getData = async () => {
            const receiptTypes = await dummyReceiptTypes();
            setReceiptTypes(receiptTypes);
        };
        getData();
    }, []);

    return (
        <Fragment>
            <Container maxWidth="sm">
                <Card sx={{ minWidth: 375 }}>
                    <CardContent>
                        <form onSubmit={formik.handleSubmit}>
                        
                            <div className={cssClasses.itemForm}>
                                <LocalizationProvider dateAdapter={AdapterLuxon}>
                                    <DateTimePicker
                                        value={formik.values.receiptDate}
                                        onChange={(value): void => {
                                            console.log('value ' + value);
                                            if (value) {
                                                const dateTimeSelected = DateTime.fromISO(value);
                                                console.log('dateTimeSelected ' + dateTimeSelected.toJSDate());
                                                formik.setFieldValue("receiptDate", dateTimeSelected);
                                            } else {
                                                formik.setFieldValue("receiptDate", value);
                                            }
                                        }}
                                        renderInput={(params: any) => <TextField {...params} label="Fecha Recepción" fullWidth />}
                                    />
                                 </LocalizationProvider>
                                 <FormHelperText className={cssClasses.messageError}>{formik.touched.receiptDate && formik.errors.receiptDate}</FormHelperText>
                            </div>
                            
                            <div className={cssClasses.itemForm}>
                                <Autocomplete
                                    id="inboundOrderNumber"
                                    sx={{ width: 300 }}
                                    getOptionLabel={(option) => {
                                        return option.inboundNumber
                                        }
                                    }
                                    filterOptions={(x) => x}
                                    options={filteredInboundOrders}
                                    autoComplete
                                    includeInputInList
                                    filterSelectedOptions
                                    value={selectedInboundOrder}
                                    isOptionEqualToValue={(option, value) => option.inboundNumber === value.inboundNumber}
                                    onChange={(event, newValue) => {
                                        if (newValue && typeof newValue !== 'string') {
                                            setSelectedInboundOrder(newValue);
                                            formik.setFieldValue('inboundOrderNumber', newValue.inboundNumber);
                                        }
                                    }}
                                    onInputChange={async (event, newInputValue) => {
                                        if (newInputValue !== '') {
                                            const inboundOrders = await dummyInboundOrders();
                                            const filterInboundOrders = inboundOrders.filter((inbOrd) => {
                                                return inbOrd.inboundNumber.startsWith(newInputValue);
                                            }) 
                                            setFilteredInboundOrders(filterInboundOrders);
                                        } else {
                                            const emptyListOfInboundOrders: inboundOrderDTO[] = []
                                            setFilteredInboundOrders(emptyListOfInboundOrders);
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="N° Orden Entrada" fullWidth />}
                                />
                                <FormHelperText className={cssClasses.messageError}>{formik.touched.inboundOrderNumber && formik.errors.inboundOrderNumber}</FormHelperText>
                            </div>

                            <div className={cssClasses.itemForm}>
                                <FormControl fullWidth className={cssClasses.selectFormControl}>
                                    <InputLabel id="lblReceiptType" className={cssClasses.labelSelect}>Tipo Recepción</InputLabel>
                                    <Select
                                        labelId="lblReceiptType"
                                        id="receptionType"
                                        name="receptionType"
                                        value={formik.values.receptionType}
                                        onChange={formik.handleChange}
                                    >
                                        <MenuItem className={cssClasses.menuItem} key={0} value="0">
                                            <em>Seleccione</em>
                                        </MenuItem>
                                        {receiptTypes.map((receiptType: receiptTypeDto) => (
                                            <MenuItem className={cssClasses.menuItem} key={receiptType.id} value={receiptType.id}>{receiptType.name}</MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText className={cssClasses.messageError}>{formik.touched.receptionType && formik.errors.receptionType}</FormHelperText>
                                </FormControl>
                            </div>

                            <div className={cssClasses.itemForm}>
                                <TextField
                                    fullWidth
                                    id="location"
                                    name="location"
                                    label="Ubicación Recepción"
                                    value={formik.values.location}
                                    onChange={formik.handleChange}
                                    error={formik.touched.location && Boolean(formik.errors.location)}
                                    helperText={formik.touched.location && formik.errors.location}
                                />
                            </div>

                            <div className={cssClasses.itemForm}>
                                <TextField
                                    fullWidth
                                    id="forklift"
                                    name="forklift"
                                    label="Máquina"
                                    value={formik.values.forklift}
                                    onChange={formik.handleChange}
                                    error={formik.touched.forklift && Boolean(formik.errors.forklift)}
                                    helperText={formik.touched.forklift && formik.errors.forklift}
                                />
                            </div>
  
                            <Button color="primary" variant="contained" fullWidth type="submit">
                                Enviar
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            </Container>
        </Fragment>
    );
}

export default ReceiptForm;