import React, { useState, /*useEffect*/ } from "react";
import classes from "./UomType.module.css";
import VendorTable from "./UomTypeTable";
import Dialogo from "../../../components/UI/DialogInfo";

/*
interface IMessage {
  message: string;
}*/

function UomType() {
  const [/*message*/, setMessage] = useState<string>();

  console.log("Render UomType");

  return (
    <div className="classes">
      <Dialogo />
      <div className={classes.Titulo}>
        <div className={classes.Card}>
        <h3>Maestros ➡️ Registros Unidades de Medida</h3>
        </div>
      </div>


      <VendorTable setMessage={setMessage} />
    </div>
  );
}

export default UomType;
