import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
////import { MouseEventHandler } from 'react';

export default function Clients() {
  const options = clients.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  return (
    <Autocomplete
      id="grouped-demo"
      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.title}
 
      renderInput={(params) => <TextField {...params} label="Clientes" />}
    />
  );
}


const clients = [
  { title: 'Marval', year: 1994 },
  { title: 'Texora', year: 1994 },
  { title: 'Ripley', year: 1994 },
  { title: 'Falabella', year: 1994 },
  { title: 'Titanium', year: 1994 },
  { title: 'Brightstar', year: 1994 },
  { title: 'Ejemplo 1', year: 1994 },
  { title: 'Ejemplo 2', year: 1994 },
  { title: 'Ejemplo 3', year: 1994 },
  { title: 'Ejemplo 4', year: 1994 },
  { title: 'Ejemplo 5', year: 1994 },
  { title: '2345434534', year: 1994 },
  { title: 'aaaa', year: 1994 },
  { title: 'bbbbbbb', year: 1994 },
  { title: 'ccccccc', year: 1994 },
  { title: 'ddddd', year: 1994 },
  { title: 'fffffff', year: 1994 },
  { title: 'ggggggg', year: 1994 },



  
];
