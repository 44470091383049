import React /*,{Fragment}*/ from 'react'

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import classes from "./Footer.module.css"

const Footer = () => {
    return (
        <Box px={{xs: 3, sm: 10}} py={{xs: 5, sm: 10}}>
            
            <Container maxWidth="lg" className={classes.main} >
                <h1 className={classes.title}>Información General</h1>
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Box borderBottom={1}><h3>Informaciones</h3></Box>
                        <Box >
                            <Link href="/" color="inherit">
                                Contacto
                            </Link>
                        </Box>
                        <Box >
                            <Link href="/" color="inherit">
                                Soporte
                            </Link>
                        </Box>
                        <Box >
                            <Link href="/" color="inherit">
                                Informacion general
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box borderBottom={1}><h3>Servicios</h3></Box>
                        <Box >
                            <Link href="/" color="inherit">
                                Servicio tecnico
                            </Link>
                        </Box>
                        <Box >
                            <Link href="/" color="inherit">
                                Desarrollo a la medida
                            </Link>
                        </Box>
                        <Box >
                            <Link href="/" color="inherit">
                                Mesa de ayuda
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Box borderBottom={1}><h3>Dirección</h3></Box>
                        <Box >
                            <p>Av. Américo Vespucio Sur 991, Las Condes, Santiago</p>
                        </Box>
                        <Box borderBottom={1}><h3>Teléfonos</h3></Box>
                        <Box >
                            <p>(+56 2) 2 392 5000</p>
                            <p>(+56 2) 2 392 5018</p>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Footer
