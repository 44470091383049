import React, { Fragment } from "react";
import mainImage from "../../assets/warehouse2.jpg";
import classes from "./Landing.module.css";
import Summary from "./Summary";
import BtnTypescript from "../../components/UI/BtnTypescript"

import Button from "@mui/material/Button";

const onclickHandler = () => {
  alert("typescript")
}
const text = "textoBoton";

const Landing = () => {
  return (
    <Fragment>
      <Button color="error" variant="contained">
        boton temas
      </Button>
      <Button color="primary" variant="contained">
        boton temas
      </Button>
      <Button color="secondary" variant="contained">
        boton temas
      </Button>
      <BtnTypescript  onClick = {onclickHandler} text={text}/>
      
      <div className={classes.Inicio}>
        <div className={classes["main-image"]}>
          <img src={mainImage} alt="Landing" />
        </div>
      </div>
      <Summary />
    </Fragment>
  );
};

export default Landing;
