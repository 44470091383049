import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./GLOBAL.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import storeDialogInfo from "./store/UI/dialogInfo";
//import { createStore } from 'redux';
import { SnackbarProvider } from "notistack";

/*
const store = createStore(() => ({
  birds: [
    {
      name: 'gino',
      views: 1
    }
  ]
}));*/

ReactDOM.render(
  <Provider store={storeDialogInfo} >
    <SnackbarProvider maxSnack={3}>
      <App />
    </SnackbarProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
