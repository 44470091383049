import React from "react";
////import classes from "./DialogInfo.module.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

const DialogInfo = React.memo(() => {
  const dialogInfoStatus = useSelector((state) => state.dialogInfoStatus);
  const dialogInfoMessage = useSelector((state) => state.dialogInfoMessage);
  /*
  console.log("!!!");
  console.log(dialogInfoStatus);
  console.log("!!!");*/
  //alert(dialogInfoStatus); //TODO REVISAR RERENDER
  const dispatch = useDispatch();
  /*
  const activarDialogInfoHandler = () => {
    dispatch({ type: "activar" });
  };*/
  const desactivarDialogInfoHandler = () => {
    dispatch({ type: "desactivar" });
  };
  /*
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
*/
  return (
    <div>
      {/*       <Button variant="outlined" onClick={activarDialogInfoHandler}>
        Alerta
      </Button> */}
      <Dialog
        open={dialogInfoStatus}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Alerta"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogInfoMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={desactivarDialogInfoHandler}>Cancelar</Button>
          <Button onClick={desactivarDialogInfoHandler}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});

export default DialogInfo;
