import React, { useState, useEffect } from "react";
import { /*useSelector,*/ useDispatch } from "react-redux";
import MaterialTable from "@material-table/core";
import classes from "./Customer.module.css";
import porDefecto from "./CustomerUtils";
import { ExportCsv, ExportPdf } from "@material-table/exporters";
import Dialogo from "../../../components/UI/DialogInfo";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"; //iconos customizados
import {
  restGet,
  restGetColumn,
  restPost,
  restDelete,
  restUpdate,
} from "../../../respository/RESTAPI";
import { useSnackbar } from "notistack";
import { checkNulls, checkLength } from "../../../validations/validateInput";
//import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from "@mui/material/LinearProgress";
import { AxiosResponse } from "axios";
import CustomerDTO from '../../../dto/customer.dto'

//Macros
const REQUIRED_FIELDS = ["idOwn", "code", "name"];
const MAXLENGHT_FIELDS = {
  //if(obj.idOwn){ if (obj.idOwn.length > 20 )
  id: 20,
  idOwn: 20,
  code: 20,
  name: 60,
  address1Fact: 60,
  address2Fact: 60,
  idCityFact: 20,
  idStateFact: 20,
  idcountryfact: 20,
  phoneFact: 20,
  faxFact: 20,
  address1Delv: 60,
  address2Delv: 60,
  idCityDelv: 20,
  idStateDelv: 20,
  idCountryDelv: 20,
  phoneDelv: 20,
  faxDelv: 20,
  email: 100,
  priority: 20,
  timeExpected: 20,
  expirationDays: 20,
  prefixLabel: 10,
  dateCreated: 60,
  userCreated: 20,
  dateModified: 60,
  userModified: 20,
};
const url1 =
  //"https://jsonplaceholder.typicode.com/todos/1"
  "http://localhost:3000/customers";
//"https://api-dev.stglatam.app/ms/v1/maestros/vendors"
//"/ms/v1/maestros/vendors";
//"http://stapi.co/api/v1/rest/Elementos/search";
var url2 = "http://localhost:3000/owners";
/**************/

type Props = {
  setMessage: React.Dispatch<React.SetStateAction<string>>;
};

//Funcion principal
const CustomerTable = React.memo(function VendorTable(props: Props) {
  //React inits
  
  const [data, setData] = useState<CustomerDTO[]>();
  //const [dataOwners, setDataOwners] = useState();
  const [dataOwnerLookup, setDataOwnerLookup] = useState<object>();
  const [filtering, setFiltering] = useState(true);
  const [grupingToggle, setGrupingToggle] = useState(false);
  const { enqueueSnackbar /*, closeSnackbar*/ } = useSnackbar();
  const dispatch = useDispatch();
  const activarDialogInfoHandler = (messageParam: string) => {
    dispatch({ type: "activar", message: messageParam });
  };
  const [loadingState, setLoadingState] = useState(false);

  /*
  const desactivarDialogInfoHandler = () => {
    dispatch({ type: "desactivar" });
  };
  */

  //primera carga, simula onmount
  useEffect(() => {
    //placeholder
    setData(porDefecto);
    //REST get vendors
    getRest();
    getRestObj();
  }, []);

  /*****************************/
  /****       CRUD          ****/
  /*****************************/
  //REST calls
  ////GET
  const getRest = async () => {
    setLoadingState(true);
    const x = await restGet(url1, "GET");
    setLoadingState(false);
    setData(x.data);
  };
  //GET Column
  const getRestObj = async () => {
    setLoadingState(true); //elemento grafico de carga
    const x = await restGetColumn(url2, "ownName");
    setLoadingState(false); //elemento grafico de carga
    setDataOwnerLookup(x);
  };
  //CREATE
  const postRest = async (elemento: CustomerDTO) => {
    console.log("post previa");
    console.log(elemento);
    console.log("post previa");

    elemento.idOwn = +elemento.idOwn;
    setLoadingState(true);
    const x = await restPost(url1, elemento);
    setLoadingState(false);
    if (x !== null && x !== undefined) {
      if (x.status === 200) {
        enqueueSnackbar("Elemento ingresado exitosamente", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error al ingresar elemento", {
          variant: "error",
        });
      }
    }
    getRest();
  };
  //DELETE
  const deleteRest = async (elemento: CustomerDTO) => {
    console.log("borrando");
    console.log(elemento);
    console.log("borrando");

    const { id } = elemento;
    setLoadingState(true);
    const x = await restDelete(url1, id);
    setLoadingState(false);
    console.log("estadoRest:" + x.status);
    if ((x.status = 200)) {
      enqueueSnackbar("Elemento eliminado exitosamente", {
        variant: "success",
      });
    } else {
      enqueueSnackbar("Error al eliminado elemento", {
        variant: "error",
      });
    }
    getRest();
  };
  //UPDATE
  const UpdateRest = async (elemento: CustomerDTO) => {
    //reconvercion explicita ya que lookup x ser input los deja en string
    elemento.idOwn = +elemento.idOwn;
    console.log("update");
    console.log("ele:" + elemento + "url:" + url1 + "id:" + elemento.id);
    console.log("update");
    setLoadingState(true);
    const x: AxiosResponse<any> = await restUpdate(elemento, url1, elemento.id);
    setLoadingState(false);
    console.log("estadoRest:" + x.status);
    if (x !== null && x !== undefined) {
      if (x.status === 204) {
        enqueueSnackbar("Elemento modificado exitosamente", {
          variant: "success",
        });
      } else {
        enqueueSnackbar("Error al modificar elemento", {
          variant: "error",
        });
      }
    }
    getRest();
  };

  console.log("Render VendorTable");
  console.log(data);
  const columns = [
    {
      title: "ID",
      field: "id",
      defaultSort: "asc",
      /*sorting: false*/ editable: "never",
      cellStyle: { color: "blue" },
      //headerStyle: {color: "purple"}
    }, //orden por defecto o desabilitar orden y edicion
    {
      title: "Dueño",
      field: "idOwn",
      type: "numeric",
      //validate: (rowData) => (rowData.idOwn == null ? "*Requerido" : ""),
      //render: (rowData) => getOwnerName(rowData.idOwn), ///*** fucnional pero no requerido xk lo hace lookup */
      lookup: dataOwnerLookup,
      //defaultGroupOrder:0 //agrupa este campo por defecto, con valor 1 se desabilita el agrupamiento
      //hiddenByColumnsButton: true,
      validate: (rowData: CustomerDTO) => {
        if (rowData.idOwn === undefined /*|| rowData.idOwn === ""*/) {
          return "*Requerido";
        }
      },

      //validate: (rowData) => (rowData.idOwn == null ? "Requerido" : ""),
    },
    {
      title: "Cod. Proveedor",
      field: "code",
      validate: (rowData: CustomerDTO) => {
        if (rowData.code === undefined || rowData.code === "") {
          return "*Requerido";
        } else {
          if (rowData.code.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.code.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },

    /*
id : number,
    code :  string ,
    name :  string ,
    address1Fact :  string ,
    address2Fact :  string ,
    idCityFact : number,
    idStateFact : number,
    idCountryFact : number,
    phoneFact :  string ,
    faxFact :  string ,
    address1Delv :  string ,
    address2Delv :  string ,
    idCityDelv : number,
    idStateDelv : number,
    idCountryDelv : number,
    phoneDelv :  string ,
    faxDelv :  string ,
    email :  string ,
    priority : number,
    timeExpected : number,
    expirationDays : number,
    prefixLabel :  string ,
    dateCreated :  Date ,
    userCreated :  string ,
    dateModified :  Date,
    userModified :  string ,
    idOwn : number,
*/
    {
      title: "Proveedor",
      field: "name",
      validate: (rowData: CustomerDTO) => {
        if (rowData.name === undefined || rowData.name === "") {
          return "*Requerido";
        } else {
          if (rowData.name.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.name.length > 60) {
            return "Debe conterner menos de 60 caracteres";
          }
        }
      },
    },
    {
      title: "Direccion1 Fact",
      field: "address1Fact",
      validate: (rowData: CustomerDTO) => {
        if (
          rowData.address1Fact === undefined ||
          rowData.address1Fact === "" ||
          rowData.address1Fact === null
        ) {
          return;
        } else {
          if (rowData.address1Fact.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.address1Fact.length > 60) {
            return "Debe conterner menos de 60 caracteres";
          }
        }
      },
    },
    {
      title: "Direccion2 Fact",
      field: "address2Fact",

      validate: (rowData: CustomerDTO) => {
        if (
          rowData.address2Fact === undefined ||
          rowData.address2Fact === "" ||
          rowData.address2Fact === null
        ) {
          return;
        } else {
          if (rowData.address2Fact.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.address2Fact.length > 60) {
            return "Debe conterner menos de 60 caracteres";
          }
        }
      },
    },
    {
      title: "Ciudad Fact",
      field: "idCityFact",
      type: "numeric",
      hidden: true,
    },
    {
      title: "Estado Fact",
      field: "idStateFact",
      type: "numeric",
      hidden: true,
    },
    {
      title: "Pais Fact",
      field: "idCountryFact",
      type: "numeric",
      hidden: true,
    },
    {
      title: "Fono Fact",
      field: "phoneFact",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: CustomerDTO) => {
        if (rowData.phoneFact === undefined || rowData.phoneFact === "" || rowData.phoneFact === null) {
          return;
        } else {
          if (rowData.phoneFact.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.phoneFact.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Fax Fact",
      field: "faxFact",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: CustomerDTO) => {
        if (rowData.faxFact === undefined || rowData.faxFact === "" || rowData.faxFact === null) {
          return;
        } else {
          if (rowData.faxFact.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.faxFact.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Direccion1 V",
      field: "address1Delv",
      validate: (rowData: CustomerDTO) => {
        if (
          rowData.address1Delv === undefined ||
          rowData.address1Delv === "" ||
          rowData.address1Delv === null
        ) {
          return;
        } else {
          if (rowData.address1Delv.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.address1Delv.length > 60) {
            return "Debe conterner menos de 60 caracteres";
          }
        }
      },
    },
    {
      title: "Direccion2 V",
      field: "address2Delv",
      validate: (rowData: CustomerDTO) => {
        if (
          rowData.address2Delv === undefined ||
          rowData.address2Delv === "" ||
          rowData.address2Delv === null
        ) {
          return;
        } else {
          if (rowData.address2Delv.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.address2Delv.length > 60) {
            return "Debe conterner menos de 60 caracteres";
          }
        }
      },
    },
    { title: "Ciudad V", field: "idCityDelv", type: "numeric", hidden: true },
    { title: "Estado V", field: "idStateDelv", type: "numeric", hidden: true },
    { title: "Pais V", field: "idCountryDelv", type: "numeric", hidden: true },
    {
      title: "Fono V",
      field: "phoneDelv",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: CustomerDTO) => {
        if (rowData.phoneDelv === undefined || rowData.phoneDelv === ""|| rowData.phoneDelv === null) {
          return;
        } else {
          if (rowData.phoneDelv.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.phoneDelv.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Fax V",
      field: "faxDelv",
      required: false,
      export: false,
      hidden: true,
      validate: (rowData: CustomerDTO) => {
        if (rowData.faxDelv === undefined || rowData.faxDelv === ""|| rowData.faxDelv === null) {
          return;
        } else {
          if (rowData.faxDelv.length < 0) {
            return "Debe conterner mas de 0 caracteres";
          } else if (rowData.faxDelv.length > 20) {
            return "Debe conterner menos de 20 caracteres";
          }
        }
      },
    },
    {
      title: "Creación",
      field: "dateCreated",
      type: "datetime",
    },
    {
      title: "Fecha Mod.",
      field: "dateModified",
      type: "datetime",
      hidden: true,
    },
    {
      title: "Email",
      field: "email",
      hidden: false,
      validate: (rowData: CustomerDTO) => {
        if (
          rowData.email === undefined ||
          rowData.email === "" ||
          rowData.email === null
        ) {
        } else {
          if (!rowData.email.includes("@" && ".")) {
            return "Introduzca email válido";
          } else {
            if (rowData.email.length < 0) {
              return "Debe conterner mas de 0 caracteres";
            }
            if (rowData.email.length > 100) {
              return "Debe conterner menos de 100 caracteres";
            }
          }
        }
      },
    },
    
    {
      title: "Prioridad",
      field: "priority",
      type: "numeric",
      validate: (rowData: CustomerDTO) => {
        if (rowData.priority === undefined || rowData.priority === null  ) {
          
        } else {
          if (rowData.priority < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.priority.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },
  
    
    {
      title: "Tiempo Esperado",
      field: "timeExpected",
      type: "numeric",
      validate: (rowData: CustomerDTO) => {
        if (rowData.timeExpected === undefined || rowData.priority === null ) {
          
        } else {
          if (rowData.timeExpected < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.timeExpected.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },
   
    {
      title: "Días Exp",
      field: "expirationDays",
      type: "numeric",
      validate: (rowData: CustomerDTO) => {
        if (rowData.expirationDays === undefined || rowData.priority === null ) {
          
        } else {
          if (rowData.expirationDays < 0) {
            return "Debe conterner mas de 0 caracteres";
          }
          if (rowData.expirationDays.toString().length > 12) {
            return "Debe conterner menos de 12 caracteres";
          }
        }
      },
    },
    { title: "Prefijo Etiqueta", field: "prefixLabel", hidden: true },
    { title: "Usuario Crea.", field: "userCreated", hidden: true },
    { title: "Usuario Mod.", field: "userModified", hidden: true },
  ];

  return (
    <div className="App">
      <Dialogo />
      <div className={classes.Titulo}></div>
      <button onClick={() => setFiltering(!filtering)}>
        <span>Filtro Busqueda</span>
      </button>
      <button onClick={() => setGrupingToggle(!grupingToggle)}>
        <span>Filtro Agrupar</span>
      </button>
      {loadingState && <LinearProgress />}
      <MaterialTable
        title="Clientes"
        data={data}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} de {count}",
            labelRowsPerPage: "N°",
            labelRowsSelect: "Filas",
          },
          toolbar: {
            nRowsSelected: "{0} Filas Seleccionadas",
            //exportName: "Exportar CSV",
            exportTitle: "Exportar",
            exportAriaLabel: "Exportar",
            exportCSVName: "Exportar CSV",
            exportPDFName: "Exportar PDF",
            searchTooltip: "Buscar",
            searchPlaceholder: "Buscar",
            addRemoveColumns: "Agrega o quita columnas",
          },
          header: {
            actions: "Acciones",
          },
          body: {
            addTooltip: "Agregar",
            emptyDataSourceMessage: "No hay datos",
            filterRow: {
              filterTooltip: "Filtro",
            },
            editRow: {
              deleteText: "¿Esta seguro que quiere borrar esta fila?",
              saveTooltip: "Guardar",
              cancelTooltip: "Cancelar",
            },
          },
          grouping: {
            placeholder: "Arrastra las etiquetas de las columnas aqui",
          },
        }}
        columns={columns as any}
        editable={{
          //create
          onRowAdd: (newRow) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (!checkNulls(newRow, REQUIRED_FIELDS)) {
                activarDialogInfoHandler(
                  "No pueden haber campos requeridos vacios"
                );
                console.log("tiene nulos");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else if (!checkLength(newRow, MAXLENGHT_FIELDS)) {
                activarDialogInfoHandler("Infringe maximo caracteres");
                console.log("Infringe maximo caracteres");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else {
                console.log("tabledata");
                console.log(newRow);
                delete newRow["tableData"];
                console.log(newRow);
                /*
          newRow.map(r => {
            console.log(r)
            const {tableData, ...record} = r;
            return record;
          });
           
          //const {tableData, ...record} = newRow;
          const{id, idVendor} = newRow;
          const record =  {id, idVendor}
*/
                console.log("Elemento agregado");
                //const record ={ "vendorName": newRow.vendorName, "idVendor": newRow.idVendor}
                console.log(newRow);
                console.log("Elemento agregado end");
                //setData([...data, record])
                postRest(newRow);
              }
              setTimeout(() => resolve(), 100); //cierra la promesa
            }),
          //delete
          onRowDelete: (selectedRow: CustomerDTO) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (props.setMessage) {
                props.setMessage("Invalid");
                console.log("prop no validado");
                reject();
              }
              const updatedData = [...data];
              console.log(selectedRow.tableData.id);
              updatedData.splice(selectedRow.tableData.id, 1);
              console.log(selectedRow);
              deleteRest(selectedRow);
              setData(updatedData);
              setTimeout(() => {
                props.setMessage("Successfully updated!");
                resolve();
              }, 100);
            }),
          //update
          onRowUpdate: (newRow, oldRow) =>
            new Promise((resolve: (reason?: any) => void, reject) => {
              if (!checkNulls(newRow, REQUIRED_FIELDS)) {
                activarDialogInfoHandler(
                  "No pueden haber campos requeridos vacios"
                );
                console.log("tiene nulos");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else if (!checkLength(newRow, MAXLENGHT_FIELDS)) {
                activarDialogInfoHandler("Infringe maximo caracteres");
                console.log("Infringe maximo caracteres");
                setTimeout(() => reject(), 100); //cierra la promesa
              } else {
                console.log(oldRow, newRow);

                console.log("Updating");
                console.log(oldRow.tableData);
                console.log("Updating");

                const updatedData = [...data];
                updatedData[oldRow.tableData.id] = newRow;
                console.log("PostUpdating");
                console.log(newRow);
                //console.log(updatedData);
                console.log("PostUpdating");

                UpdateRest(newRow);
                //update(updatedData);
                props.setMessage("Successfully updated!");
              }
              setTimeout(() => resolve(), 100); //cierra la promesa
            }),
        }}
        actions={[
          {
            icon: () => <InsertDriveFileIcon />,
            tooltip: "Detalle",
            onClick: (e, data) => {
              alert("Descargar detalle");
              console.log(data);
            },
            //isFreeAction: true //toggle para que el boton este en la columna o generalizado
          },
        ]}
        onSelectionChange={(selectedRow) => console.log(selectedRow)}
        options={{
          sorting: true,
          //searchText:"GinoMP", //busqueda x defecto
          paging: true,
          pageSize: 20, // cantidad registros inicial
          emptyRowsWhenPaging: false, //borra lineas vacias
          pageSizeOptions: [6, 12, 20, 50, 100], // opcion dropdown cantidad elementos
          filtering: filtering, //habilita busquedas por columna
          //toolbar: true,
          //exportButton: true, //habilita csv pdf

          ////exportFileName: "Maestro_Proveedores", //nombre archivo
          addRowPosition: "first",
          actionsColumnIndex: -1,
          columnsButton: true,
          grouping: grupingToggle,
          rowStyle: (data, index) =>
            index % 2 === 0 ? { background: "#eef" } : null,
          headerStyle: {
            background: "#def",
            fontStyle: "Arial Black",
            fontSize: "1.1em",
          },

          selectionProps: (rowData: CustomerDTO) => ({
            //disable: rowData.name!=="",  //desabilitar checkbox si campo es vacio
            color: "primary",
          }),
          exportMenu: [
            {
              label: "Exportar PDF",
              exportFunc: (cols, datas) => ExportPdf(cols, datas, "vendorPDF"),
            },
            {
              label: "Exportar CSV",
              exportFunc: (cols, datas) => ExportCsv(cols, datas, "vendorCSV"),
            },
          ],
          selection: true,
          //showSelectAllCheckbox: false,
          //showTextRowsSelected: false,
        }}
      />
    </div>
  );
});

export default CustomerTable;
