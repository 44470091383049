const porDefecto: any = [
  {
    id: 1,
    idCompany: 1,
    ownCode: "Solo Local",
    ownName: "owner 1",
    tradeName: null,
    address1: null,
    address2: null,
    idCountry: null,
    idState: null,
    idCity: null,
    phone1: null,
    phone2: null,
    email: null,
    gln: "",
    dateCreated: null,
    userCreated: null,
    dateModified: null,
    userModified: null,
  },
  {
    id: 10,
    idCompany: 2,
    ownCode: "comp2ED",
    ownName: "compania2",
    tradeName: "string",
    address1: "string",
    address2: "string",
    idCountry: 0,
    idState: 0,
    idCity: 0,
    phone1: "string",
    phone2: "string",
    email: "string",
    gln: "string",
    dateCreated: "2021-10-20T19:05:00.298Z",
    userCreated: "string",
    dateModified: "2021-10-20T19:05:00.298Z",
    userModified: "string",
  },
  {
    id: 11,
    idCompany: 3,
    ownCode: "comp3ed",
    ownName: "compania3",
    tradeName: "string",
    address1: "string",
    address2: "string",
    idCountry: 0,
    idState: 0,
    idCity: 0,
    phone1: "string",
    phone2: "string",
    email: "string",
    gln: "",
    dateCreated: "2021-10-20T19:05:00.298Z",
    userCreated: "string",
    dateModified: "2021-10-20T19:05:00.298Z",
    userModified: "string",
  },
  {
    id: 14,
    idCompany: 7,
    ownCode: "comp7",
    ownName: "compañia7",
    tradeName: "string",
    address1: "string",
    address2: "string",
    idCountry: 0,
    idState: 0,
    idCity: 0,
    phone1: "string",
    phone2: "string",
    email: "string",
    gln: "string",
    dateCreated: "2021-10-28T15:10:26.258Z",
    userCreated: "string",
    dateModified: "2021-10-28T15:10:26.258Z",
    userModified: "string",
  },
];

export default porDefecto;
