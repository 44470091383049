import React, { useState, /*useEffect*/ } from "react";
import classes from "./ItemUom.module.css";
import ItemUomTable from "./ItemUomTable";
import Dialogo from "../../../components/UI/DialogInfo";

/*
interface IMessage {
  message: string;
}*/

function ItemUom() {
  const [/*message*/, setMessage] = useState<string>();

  console.log("Render ItemUom");

  return (
    <div className="classes">
      <Dialogo />
      <div className={classes.Titulo}>
        <div className={classes.Card}>
        <h3>Maestros ➡️ Registros Presentaciones</h3>
        </div>
      </div>


      <ItemUomTable setMessage={setMessage} />
    </div>
  );
}

export default ItemUom;
