const porDefecto : any = [
    {
        "id": 1,
        "code": "cod5",
        "conversionFactor": "5.0000",
        "barCode": "PLACEHOLDER",
        "uomName": "5",
        "length": "0.0000",
        "width": "0.0000",
        "height": "0.0000",
        "volume": "0.0000",
        "weight": "0.0000",
        "status": false,
        "layoutUomQty": 0,
        "layoutUnitQty": 0,
        "layoutMaxWeightUpon": "0.0000",
        "putawayZone": null,
        "pickArea": null,
        "specialField1": "ED1",
        "specialField2": null,
        "specialField3": null,
        "specialField4": null,
        "dateCreated": null,
        "userCreated": null,
        "dateModified": null,
        "userModified": null,
        "idItem": 1,
        "idUomType": 5,
        "item": {
            "id": 1,
            "code": "COD1",
            "altItemCode": "COD1",
            "description": "ITEM O1",
            "idGrpItem1": null,
            "idGrpItem2": null,
            "idGrpItem3": null,
            "idGrpItem4": null,
            "longName": "ITEM 01",
            "shortName": "ITEM 01",
            "status": true,
            "comment": null,
            "shelfLife": null,
            "expirationDays": null,
            "ctrlSerialInbound": null,
            "ctrlSerialInternal": null,
            "ctrlSerialOutbound": null,
            "lotControlInbound": null,
            "lotControlInternal": null,
            "lotControlOutbound": null,
            "weight": null,
            "volume": null,
            "length": null,
            "width": null,
            "height": null,
            "nestedVolume": null,
            "inspectionRequerid": null,
            "inspectionCode": null,
            "idPutawayZone": null,
            "controlExpDate": null,
            "controlFabDate": null,
            "acumulable": null,
            "reOrderPoint": null,
            "reOrderQty": null,
            "palletQty": null,
            "cutMinimum": null,
            "originator": null,
            "vasProfile": null,
            "hazard": null,
            "price": null,
            "inventoryType": null,
            "stackingSequence": null,
            "commentControl": null,
            "compatibilityCode": null,
            "MSDSUrl": null,
            "pictureUrl": null,
            "grpClass1": null,
            "grpClass2": null,
            "grpClass3": null,
            "grpClass4": null,
            "grpClass5": null,
            "grpClass6": null,
            "grpClass7": null,
            "grpClass8": null,
            "specialField1": null,
            "specialField2": null,
            "specialField3": null,
            "specialField4": null,
            "dateCreated": null,
            "userCreated": null,
            "dateModified": null,
            "userModified": null,
            "idOwn": 1
        },
        "uomtype": {
            "idUomType": 5,
            "idOwn": 18,
            "uomName": "sss",
            "handlesDecimal": false,
            "dateCreated": "2021-11-10T19:16:45.000Z",
            "userCreated": "sss",
            "dateModified": null,
            "userModified": null,
            "isVariableWeight": false,
            "overPickingAllowed": false
        }
    }
  
]



export default porDefecto