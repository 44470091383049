class Receipt {
    id?: number;
    idInboundOrder: number;
    receiptDate: Date; 
    status: boolean; 
    referenceDoc?: string;
    idReferenceDocType?: number;
    idCarrier?: number;
    idTruckCode?: string;
    driverCode?: string;
    shiftNumber?: string;
    idWhs: number; 
    userWms?: string;
    idLocationForkLift?: string;
    idLocationStage?: string;
    dateCreated: Date;
    userCreated: string;
    dateModified: Date;
    userModified: string;
    idType: number;
}

export default Receipt;