import React from "react";
import ReceiptForm from '../../components/receipt/receipt-form'

const CreateReceipt  = () => {
    return (
        <div className="classes">
            <ReceiptForm />
        </div>
    );
}

export default CreateReceipt;