import React, { Fragment } from "react";
import MainHeader from "./MainHeader";
import Footer from "./Footer";
//import NavMenu from "./NavMenu";

type Props = {
  children: React.ReactNode
};

const Layout = (props: Props) => {
  return (
    <Fragment>
      <MainHeader />

     {/*  <NavMenu /> */}
      <main>{props.children}</main>
      <Footer />
    </Fragment>
  );
};

export default Layout;
