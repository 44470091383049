import React, { useState, /* useEffect */ } from "react";
import classes from "./Owner.module.css";
import OwnerTable from "./OwnerTable";
import Dialogo from "../../../components/UI/DialogInfo";

/*
interface IMessage {
  message: string;
}*/

function Owner() {
  const [/* message */, setMessage] = useState<string>();

  console.log("Render Owner");

  return (
    <div className="classes">
      <Dialogo />
      <div className={classes.Titulo}>
        <div className={classes.Card}>
        <h3>Maestros ➡️ Registros Dueños</h3>
        </div>
      </div>


      <OwnerTable setMessage={setMessage} />
    </div>
  );
}

export default Owner;
