import axios from "axios";
/*
import React, { useState, useEffect } from "react";
import porDefecto from "./VendorUtils";*/
/*
//GET 
export async function createRequest(urlParam) {
  try {
    const response = await axios.get(urlParam, config);
    console.log("respuesta axios get");
    console.log(response.data);
    console.log("respuesta axios get");
    //setState({ data: response.data });
    ////setData(response.data);
    return response;
  } catch (err) {
    console.error(err);
  }
}
*/
//GET
export const restGet = async (url, method) => {
  console.log("Realizando peticion GET axios")
  const response = await axios({
    url: url,
    method: method,
  });
  console.log("respuesta axios get");
  console.log(response);
  console.log("respuesta axios get");
  return response;
};
/*
export async function suma(a, b) {
  console.log("sumando " + a + "+" + b);
  return a + b;
}*/

//GET Column
var ownersLookup;
export async function restGetColumn(urlObject, objectGet) {
  try {
    const response = await axios.get(urlObject, config);
    //setState({ data: response.data });

    ////setDataOwners(response.data);
    var lista = {};

    response.data.map((o) => {
      lista[o.id] = o[objectGet];
      return o[objectGet];
    });
    ownersLookup = lista;
    console.log("restGetColumn");
    console.log(ownersLookup);
    console.log("restGetColumn");
    return ownersLookup;
  } catch (err) {
    console.error(err);
  }
}

//Create
export const restPost = async (url, elemento) => {
  //delete this.state.form.id;
  console.log("post");
  console.log(elemento);
  console.log("post");
  try {
    //elemento.idOwn = "hola" //simular error
    const response = await axios.post(url, elemento);
    console.log("post2");
    console.log(response);
    console.log("post2");
    return response;
  } catch (err) {
    console.log("error")
    let errCode = err.response.data.error.code
    console.error(errCode);
    console.log("error")
    return errCode
  
  }
};

//Delete
export const restDelete = async (url, elemento) => {
  console.log("eliminando");
  console.log(elemento);
  console.log("eliminando");
  try {
    const response = await axios.delete(url + "/" + elemento);
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

function removeNulls(obj) {
  var propNames = Object.getOwnPropertyNames(obj);
  for (var i = 0; i < propNames.length; i++) {
    var propName = propNames[i];
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName];
    }
  }
}

//Update
export const restUpdate = async (elemento, url, id) => {
  console.log("updating");
  /*
  delete elemento.tableData; //borrar index table
  elemento.idOwn = +elemento.idOwn; //reconvercion explicita ya que lookup x ser input los deja en string
  let { tableData, ...record } = elemento; //borrar index table
  record = JSON.parse(JSON.stringify(record)); //borrar atributos nulos
  clean(record);

  console.log("ppp");
  console.log(record);
  console.log("ppp");
  */

  delete elemento.tableData; //borrar index table
  let { tableData, ...record } = elemento; //borrar index table
  record = JSON.parse(JSON.stringify(record)); //borrar atributos nulos
  removeNulls(record);

  console.log("ppp");
  console.log(record);
  console.log("ppp");

  try {
    const response = await axios.put(url + "/" + id, record);
    return response;
  } catch (err) {
    console.error(err);
  }


};
/*
//configuraciones REST para Axios
const url =
  //"https://jsonplaceholder.typicode.com/todos/1"
  "http://localhost:3000/vendors";
//"https://api-dev.stglatam.app/ms/v1/maestros/vendors"
//"/ms/v1/maestros/vendors";
//"http://stapi.co/api/v1/rest/Elementos/search";
const urlOwners =
  //"https://jsonplaceholder.typicode.com/todos/1"
  "http://localhost:3000/owners";
//"https://api-dev.stglatam.app/ms/v1/maestros/vendors"
//"/ms/v1/maestros/vendors";
//"http://stapi.co/api/v1/rest/Elementos/search"
*/
const getToken = () => {
  return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJiRUhmQmNkd1RnNkY2SGhHdGVkcnRFN0pSRVNmVU40cSIsImlhdCI6MTYzMDA2OTU0MywiZXhwIjoxNjMwMDY5ODQzfQ.sKhM5IIf0aevBmlYVPozMOiz704kVtWyQ_2svD2klXc";
};
const config = {
  //mode: 'no-cors',
  headers: {
    "Content-Type": "application/json",
    //'Content-Type': 'application/json',
    //'Authorization': `Bearer ${}`,
    //eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJiRUhmQmNkd1RnNkY2SGhHdGVkcnRFN0pSRVNmVU40cSIsImlhdCI6MTYzMDA2OTU0MywiZXhwIjoxNjMwMDY5ODQzfQ.sKhM5IIf0aevBmlYVPozMOiz704kVtWyQ_2svD2klXc
    Authorization: "Bearer " + getToken(),
    "X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
  },
  //withCredentials: true,
  //credentials: 'same-origin',
  crossorigin: true,
};
/*
axios.defaults.headers.common = {'Authorization': "Bearer "+ getToken()}
axios.defaults.headers.common['Content-Type'] ='application/json;charset=utf-8';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
*/
/*
  axios.interceptors.request.use(
    (config) => {
      const token = getToken();
      const auth = token ? `Bearer ${token}` : "";
      config.headers.common["Authorization"] = auth;
      return config;
    },
    (error) => Promise.reject(error)
  );
*/
/*
async function fetchVendor() {
  try {
    const x = await fetch(url, {
      //credentials: 'include',
      headers: {
        Authorization: "Bearer " + getToken(),
        "Content-Type": "application/json",
      },
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => console.log(data));

    console.log("fetch");
    console.log(x);
    console.log("fetch");
  } catch (error) {
    console.log("error fetch");
    console.log(error);
    console.log("error fetch");
  }
}
fetchVendor();

const VendorRest = () => {};
*/

export default { restGet, restGetColumn, restPost, restDelete, restUpdate };
