import React, { useState, /*useEffect*/ } from "react";
import classes from "./Device.module.css";
import VendorTable from "./DeviceTable";
import Dialogo from "../../../components/UI/DialogInfo";

/*
interface IMessage {
  message: string;
}*/

function Vendor() {
  const [/*message*/, setMessage] = useState<string>();

  console.log("Render Device");

  return (
    <div className="classes">
      <Dialogo />
      <div className={classes.Titulo}>
        <div className={classes.Card}>
        <h3>Maestros ➡️ Registros Dispositivos</h3>
        </div>
      </div>


      <VendorTable setMessage={setMessage} />
    </div>
  );
}

export default Vendor;
