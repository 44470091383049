const porDefecto : any = [
    {
      idVendor: 2,
      idOwn: 11,
      vendorCode: "DATOS SOLO DEMOSTRACION1",
      vendorName: "DATOS SOLO DEMOSTRACION1",
      address1: "DATOS SOLO DEMOSTRACION1",
      address2: "DATOS SOLO DEMOSTRACION1",
      idCountry: 0,
      idState: 0,
      idCity: 0,
      phone: "string",
      fax: "string",
      email: "string",
      hasInspection: 0,
      SpecialField1: "string",
      SpecialField2: "string",
      SpecialField3: "string",
      SpecialField4: "string",
      dateCreated: "2021-10-12T17:36:35.509Z",
      userCreated: "string",
      dateModified: "2021-10-12T17:36:35.509Z",
      userModified: "string",
    },
    {
      idVendor: 3,
      idOwn: 11,
      vendorCode: "DATOS SOLO DEMOSTRACION2",
      vendorName: "DATOS SOLO DEMOSTRACION2",
      address1: "DATOS SOLO DEMOSTRACION2",
      address2: "DATOS SOLO DEMOSTRACION2",
      idCountry: 0,
      idState: 0,
      idCity: 0,
      phone: "string",
      fax: "string",
      email: "string",
      hasInspection: 0,
      SpecialField1: "string",
      SpecialField2: "string",
      SpecialField3: "string",
      SpecialField4: "string",
      dateCreated: "2021-10-12T17:36:35.509Z",
      userCreated: "string",
      dateModified: "2021-10-12T17:36:35.509Z",
      userModified: "string",
    },
    {
      idVendor: 4,
      idOwn: 10,
      vendorCode: "terc",
      vendorName: "tercero",
      address1: "string",
      address2: "string",
      idCountry: 0,
      idState: 0,
      idCity: 0,
      phone: "string",
      fax: "string",
      email: "string",
      hasInspection: 0,
      SpecialField1: "string",
      SpecialField2: "string",
      SpecialField3: "string",
      SpecialField4: "string",
      dateCreated: "2021-10-12T17:36:35.509Z",
      userCreated: "string",
      dateModified: "2021-10-12T17:36:35.509Z",
      userModified: "string",
    },
    {
      idVendor: 5,
      idOwn: 1,
      vendorCode: "76091150-k",
      vendorName: "Christian Hartgwig",
      address1: "Luis Pasteur 7777",
      address2: "null",
      idCountry: 2,
      idState: 7,
      idCity: 1,
      phone: "56982400808",
      fax: "null",
      email: "chartwig@propschile.cl",
      hasInspection: 0,
      SpecialField1: "null",
      SpecialField2: "null",
      SpecialField3: "null",
      SpecialField4: "null",
      dateCreated: "2021-10-12T17:36:35.509Z",
      userCreated: "GinoMP",
      dateModified: "2021-10-12T17:36:35.509Z",
      userModified: "GinoMP",
    },
    {
      idVendor: 6,
      idOwn: 5,
      vendorCode: "1111111-1",
      vendorName: "Enzo Viazo",
      address1: "Santa Lucia330",
      address2: null,
      idCountry: 2,
      idState: 7,
      idCity: 1,
      phone: "56934234348",
      fax: null,
      email: "chartwig@propschile.cl",
      hasInspection: 0,
      SpecialField1: null,
      SpecialField2: null,
      SpecialField3: null,
      SpecialField4: null,
      dateCreated: "2021-10-12T17:36:35.509Z",
      userCreated: "GinoMP",
      dateModified: "2021-10-12T17:36:35.509Z",
      userModified: "GinoMP",
    },
    {
      idVendor: 7,
      idOwn: 6,
      vendorCode: "1222222-1",
      vendorName: "Mario Bros",
      address1: "sdfsdfdsf",
      address2: null,
      idCountry: 1,
      idState: 1,
      idCity: 3,
      phone: "555",
      fax: null,
      email: "1@mail.cl",
      hasInspection: 1,
      SpecialField1: null,
      SpecialField2: null,
      SpecialField3: null,
      SpecialField4: null,
      dateCreated: "2021-10-15T14:36:35.509Z",
      userCreated: "GinoMP",
      dateModified: "2021-10-15T14:36:35.509Z",
      userModified: "GinoMP",
    },
    {
      idVendor: 8,
      idOwn: 1,
      vendorCode: "1",
      vendorName: "1",
      address1: "1",
      address2: null,
      idCountry: null,
      idState: null,
      idCity: null,
      phone: null,
      fax: null,
      email: null,
      hasInspection: null,
      SpecialField1: null,
      SpecialField2: null,
      SpecialField3: null,
      SpecialField4: null,
      dateCreated: null,
      userCreated: null,
      dateModified: null,
      userModified: null,
    },
    {
      idVendor: 9,
      idOwn: 9,
      vendorCode: "9",
      vendorName: "9",
      address1: "9",
      address2: "9",
      idCountry: null,
      idState: null,
      idCity: null,
      phone: null,
      fax: null,
      email: null,
      hasInspection: null,
      SpecialField1: null,
      SpecialField2: null,
      SpecialField3: null,
      SpecialField4: null,
      dateCreated: "2021-10-02T02:13:00.000Z",
      userCreated: null,
      dateModified: null,
      userModified: null,
    },
    {
      idVendor: 10,
      idOwn: 99,
      vendorCode: "99",
      vendorName: "99",
      address1: "99",
      address2: "99",
      idCountry: null,
      idState: null,
      idCity: null,
      phone: null,
      fax: null,
      email: null,
      hasInspection: null,
      SpecialField1: null,
      SpecialField2: null,
      SpecialField3: null,
      SpecialField4: null,
      dateCreated: "2021-10-13T13:39:23.878Z",
      userCreated: null,
      dateModified: null,
      userModified: null,
    },
    {
      idVendor: 12,
      idOwn: 5,
      vendorCode: "5",
      vendorName: "5",
      address1: "5",
      address2: "5",
      idCountry: null,
      idState: null,
      idCity: null,
      phone: null,
      fax: null,
      email: null,
      hasInspection: null,
      SpecialField1: null,
      SpecialField2: null,
      SpecialField3: null,
      SpecialField4: null,
      dateCreated: "2021-10-13T17:10:08.039Z",
      userCreated: null,
      dateModified: null,
      userModified: null,
    },
    {
      idVendor: 13,
      idOwn: 333,
      vendorCode: "333ed3",
      vendorName: "333",
      address1: "333",
      address2: "333",
      idCountry: 5,
      idState: 1,
      idCity: 1,
      phone: "1212",
      fax: "dsfdsf",
      email: "dsfdsf",
      hasInspection: 1,
      SpecialField1: "dsfsdf",
      SpecialField2: "dfdsf",
      SpecialField3: "dsfsdf",
      SpecialField4: "sdf",
      dateCreated: "2021-10-13T18:01:27.918Z",
      userCreated: "dfdf",
      dateModified: "2021-10-13T18:15:38.880Z",
      userModified: "dsf",
    },
    {
      idVendor: 14,
      idOwn: 333,
      vendorCode: "333",
      vendorName: "333",
      address1: "333",
      address2: "333",
      idCountry: null,
      idState: null,
      idCity: null,
      phone: null,
      fax: null,
      email: null,
      hasInspection: null,
      SpecialField1: null,
      SpecialField2: null,
      SpecialField3: null,
      SpecialField4: null,
      dateCreated: "2021-10-13T18:01:27.918Z",
      userCreated: null,
      dateModified: null,
      userModified: null,
    },
  ];



export default porDefecto