//import { /*createTheme*/ createMuiTheme } from '@material-ui/core/styles';
//import blue from '@mui/material/colors/blue';
//import { createMuiTheme } from 'material-ui';
//import { createTheme } from '@material-ui/core/styles'
import { createTheme} from '@mui/material/styles';

const customTheme = createTheme({
    palette: {
        primary: {
            main: '#1976d2'
        },
        secondary: {
            main: '#e4fbfb'
        },
        tertiary: {
            main: '#ddd'
        },
        success: {
            main: '#2e7d32'
        },
        error: {
            main: '#d32f2f'
        },
        warning: {
            main: '#ED6C02'
        },
        info: {
            main: '#0288d1'
        },
        bgPrimary: {
            main: '#C5C5C6'
        },    
        bgSecondary: {
            main: '#a8e4a0'
        }
    }
})

export default customTheme;