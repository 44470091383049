import React, { useState /*useEffect*/ } from "react";
import classes from "./Item.module.css";
import ItemUomTable from "./ItemTable";
import Dialogo from "../../../components/UI/DialogInfo";
import FilterBar from "../../../components/Layouts/FiltersBar";
/*
interface IMessage {
  message: string;
}*/

function ItemUom() {
  const [, /*message*/ setMessage] = useState<string>();

  console.log("Render ItemUom");

  return (
    <div className="classes">
      <Dialogo />
      <div className={classes.Titulo}>
        <div className={classes.Card}>
          <h3>Maestros ➡️ Registros Ítems</h3>
        </div>
      </div>
      <div className={classes.FilterBar}>
        <FilterBar />
      </div>
      <ItemUomTable setMessage={setMessage} />
    </div>
  );
}

export default ItemUom;
